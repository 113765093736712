// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Text, Center, Button, VStack } from '@chakra-ui/react';
import { Decorator } from '../components/Decorator';
import { PageContainer } from '../components/PageContainer';

export const ExpiredRoom = () => {
  const { push } = useHistory();

  const goToHome = () => {
    push(`/`);
  };

  return (
    <PageContainer>
      <Decorator>
        <Center mt="64px" height="65%">
          <VStack spacing="36px">
            <Text
              fontSize={{ base: '29px', md: '36px' }}
              fontWeight="700"
              textShadow="-2px -2px 0 #FFF, 2px -2px 0 #FFF, -2px 2px 0 #FFF, 2px 2px 0 #FFF"
            >
              Space has expired
            </Text>
            <Button
              onClick={() => goToHome()}
              height={{ base: '38px', md: '48px' }}
              textColor="#fff"
              background="#1B3A57"
              _hover={{ bg: '#77a6b5' }}
            >
              <Text color="white" fontSize="16px">
                Return to Home
              </Text>
            </Button>
          </VStack>
        </Center>
      </Decorator>
    </PageContainer>
  );
};

export default ExpiredRoom;
