import { Box, BoxProps } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { heroSpriteSelector, heroEmoteSelector } from '../state/hero';
import { Emote } from '../components/Emote';

interface PlayerProps extends BoxProps {
  pixelSize: number;
  facing: string;
  isWalking: boolean;
  x: number;
  y: number;
}

interface Skin {
  idle: string;
  walking: string;
}

const Player = ({
  pixelSize,
  facing,
  isWalking,
  x: propX,
  y: propY,
}: PlayerProps) => {
  const sprite = useRecoilValue(heroSpriteSelector);
  const emoteKey = useRecoilValue(heroEmoteSelector);
  const [gridCell, setGridCell] = useState<number>(pixelSize * 16);
  const [skin, setSkin] = useState<Skin>();
  const [x, setX] = useState<number>(propX); // Spawn X
  const [y, setY] = useState<number>(propY); // Spawn Y

  const [emoteActive, setEmoteActive] = useState<boolean>(false);

  const handleEmote = () => {
    if (!emoteActive) {
      setEmoteActive(true);
      setTimeout(() => {
        setEmoteActive(false);
      }, 3000);
    }
  };

  useEffect(() => {
    if (emoteKey) {
      handleEmote();
    }
  }, [emoteKey]);

  useEffect(() => {
    setX(propX);
    setY(propY);
  }, [propX, propY]);

  useEffect(() => {
    setGridCell(pixelSize * 16);
  }, [pixelSize]);

  useEffect(() => {
    setSkin({
      idle: `/assets/characters/${sprite}_idle_anim_32x32.png`,
      walking: `/assets/characters/${sprite}_run_32x32.png`,
    });
  }, [sprite]);

  const Move = (_x: number, _y: number, _gridCell: number) =>
    `translate3d(${_x * _gridCell}px, ${_y * _gridCell}px, 0)`;

  return (
    <Box
      position="absolute"
      width={`${gridCell * 1}px`}
      height={`${gridCell * 2}px`}
      marginTop={`-${gridCell * 1.1}px`}
      zIndex={y}
    >
      {emoteActive && (
        <Box
          position="absolute"
          transform={Move(x, y, gridCell)}
          transition="linear 0.2s"
          marginTop={`-${gridCell * 1.1}px`}
          userSelect="none"
        >
          <Emote emoteKey={emoteKey} />
        </Box>
      )}

      <Box
        width={`${gridCell * 1}px`}
        height={`${gridCell * 2}px`}
        overflow="hidden"
        transform={Move(x, y, gridCell)}
        transition="linear 0.2s"
      >
        <Box
          animation={`walkAnimation_${facing} ${
            isWalking ? '0.6s' : '1.2s'
          } steps(6) infinite`}
          position="absolute"
          bottom="0"
          background={`url(${
            isWalking ? skin?.walking : skin?.idle
          }) no-repeat no-repeat`}
          backgroundSize="100%"
          width={`${(pixelSize * 768) / 2}px`} // pixelsize * image width divided in half because image is 32x32 resolution per unit, while map is only 16 atm
          height={`${(pixelSize * 64) / 2}px`} // pixelsize * image width divided in half because image is 32x32 resolution per unit, while map is only 16 atm
          style={{
            imageRendering: 'pixelated',
          }}
        />
      </Box>
    </Box>
  );
};

export default Player;
