import { selector } from 'recoil';
import {
  roomIdState,
  mapState,
  maximisedState,
  mapPanState,
  roomIsLoadingState,
  joystickDirectionState,
  isFocusedState,
} from './atoms';

export const roomIdSelector = selector<Room['roomId']>({
  key: 'room-id',
  set: ({ set }, roomId) => set(roomIdState, roomId),
  get: ({ get }) => get(roomIdState),
});

export const roomIsLoadingSelector = selector<boolean>({
  key: 'room-is-loading',
  set: ({ set }, isLoading) => set(roomIsLoadingState, isLoading),
  get: ({ get }) => get(roomIsLoadingState),
});

export const mapSelector = selector<Room['map']>({
  key: 'map-name',
  set: ({ set }, mapName) => set(mapState, mapName),
  get: ({ get }) => get(mapState),
});

export const maxizedSelector = selector<Room['isMaximized']>({
  key: 'is-maximized',
  set: ({ set }, isMaximized) => set(maximisedState, isMaximized),
  get: ({ get }) => get(maximisedState),
});

export const mapPanSelector = selector<Room['mapPan']>({
  key: 'map-pan',
  set: ({ set }, coordinates) => set(mapPanState, coordinates),
  get: ({ get }) => get(mapPanState),
});

export const joystickDirectionSelector = selector<string | null>({
  key: 'joystick-direction',
  set: ({ set }, direction) => set(joystickDirectionState, direction),
  get: ({ get }) => get(joystickDirectionState),
});

export const isFocusedSelector = selector<boolean>({
  key: 'is-focused',
  set: ({ set }, isFocused) => set(isFocusedState, isFocused),
  get: ({ get }) => get(isFocusedState),
});
