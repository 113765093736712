import { Box, HStack, Text, IconButton, BoxProps } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarked } from '@fortawesome/free-solid-svg-icons';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  villagerFamilySelector,
  villagerListSelector,
} from '../state/villagers';
import { mapPanSelector } from '../state/room';

const VillagerListItem = ({ villagerId }: { villagerId: string }) => {
  const [villager] = useRecoilState(villagerFamilySelector(villagerId)) as [
    Villager,
    () => void
  ];
  const setMapPanChoordinates = useSetRecoilState(mapPanSelector);

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getStatusColor = () => {
    if (villager?.data?.status === 'online') {
      return '#42E583';
    }
    if (villager?.data?.status === 'blurred') {
      return '#FFA620';
    }
    return '#000';
  };

  return (
    <Box
      marginBottom="6px"
      borderRadius="6px"
      backgroundColor="#3F3F3F"
      px="12px"
      opacity="0.9"
      marginRight="auto"
    >
      <HStack>
        <Box
          width="8px"
          height="8px"
          borderRadius="4px"
          backgroundColor={getStatusColor()}
        />
        <Text fontSize="14px" color="#FFF">
          {capitalizeFirstLetter(villager.name)}
        </Text>
        <IconButton
          onClick={() =>
            setMapPanChoordinates({
              x: villager.data.position.coordinates.x,
              y: villager.data.position.coordinates.y,
            })
          }
          aria-label="Navigate to user"
          color="#FFF"
          size="sm"
          variant="ghost"
          _hover={{ bg: 'transparent' }}
          icon={<FontAwesomeIcon color="#FFF" icon={faMapMarked} />}
        />
      </HStack>
    </Box>
  );
};

export const ConnectedVillagerList = (props: BoxProps) => {
  const villagersTotalInRoom = useRecoilValue(villagerListSelector) as string[];

  return (
    <Box {...props}>
      {villagersTotalInRoom &&
        villagersTotalInRoom.map((villagerId: string) => (
          <Box key={villagerId}>
            <VillagerListItem villagerId={villagerId} />
          </Box>
        ))}
    </Box>
  );
};

export default ConnectedVillagerList;
