// @ts-nocheck
import { selectorFamily, selector, DefaultValue } from 'recoil';
import { villagerFamilyState, villagerListState } from './atoms';

export const villagerFamilySelector = selectorFamily<Villager>({
  key: 'villager-list-family',
  get:
    (id) =>
    ({ get }) =>
      get(villagerFamilyState(id)),
  set:
    (id) =>
    ({ set }, villager) => {
      set(villagerFamilyState(id), villager);
    },
});

export const villagerListSelector = selector({
  key: 'villager-list',
  set: ({ set }, list) => {
    if (list instanceof DefaultValue) {
      set(villagerListState, list);
      return;
    }

    const idList: Villager['id'][] = [];
    list.forEach((villager) => {
      idList.push(villager.id);
      set(villagerFamilyState(villager.id), villager);
    });
    set(villagerListState, idList);
  },
  get: ({ get }) => get(villagerListState),
});

export const addVillagerSelector = selector({
  key: 'add-villager',
  set: ({ set, get }, villager) => {
    const list = get(villagerListState);
    // This prevents a bug, but is just repairing some fundamental issue elsewhere.
    if (!list.includes(villager.id)) {
      set(villagerListState, [...list, villager.id]);
      set(villagerFamilyState(villager.id), villager);
    }
  },
});

export const removeVillagerSelector = selector({
  key: 'remove-villager',
  set: ({ set, get }, villagerId) => {
    const list = get(villagerListState);
    const found = list.findIndex((vId) => vId === villagerId);
    if (found === -1) return;
    set(villagerListState, [...list.filter((vId) => vId !== villagerId)]);
  },
});

export const moveVillagerSelector = selector({
  key: 'move-villager',
  set: ({ set }, { villagerId, position }) => {
    set(villagerFamilyState(villagerId), (villager) => {
      return {
        ...villager,
        data: {
          ...villager.data,
          position,
        },
      };
    });
  },
});

export const toggleVillagerCameraSelector = selector({
  key: 'togle-villager-camera',
  set: ({ set }, { villagerId, isCameraEnabled }) => {
    set(villagerFamilyState(villagerId), (villager) => {
      return {
        ...villager,
        media: {
          ...villager.media,
          isCameraEnabled,
        },
      };
    });
  },
});

export const villagerEmoteSelector = selector({
  key: 'villager-emote',
  set: ({ set }, { villagerId, emoteKey }) => {
    set(villagerFamilyState(villagerId), (villager) => {
      return {
        ...villager,
        data: {
          ...villager.data,
          emoteKey,
        },
      };
    });
  },
});

export const villagerStatusSelector = selector({
  key: 'villager-status',
  set: ({ set }, { villagerId, status }) => {
    set(villagerFamilyState(villagerId), (villager) => {
      return {
        ...villager,
        data: {
          ...villager.data,
          status,
        },
      };
    });
  },
});

export const toggleVillagerMicrophoneSelector = selector({
  key: 'togle-villager-microphone',
  set: ({ set }, { villagerId, isMicrophoneEnabled }) => {
    set(villagerFamilyState(villagerId), (villager) => {
      return {
        ...villager,
        media: {
          ...villager.media,
          isMicrophoneEnabled,
        },
      };
    });
  },
});
