import { HStack, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

export const Trait = ({ trait }: { trait: string }) => {
  return (
    <HStack height="24px">
      <Text fontSize="20px" color="#42E583">
        <FontAwesomeIcon icon={faCheckCircle} />
      </Text>
      <Text fontSize="14px">{trait}</Text>
    </HStack>
  );
};

export default Trait;
