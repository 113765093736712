import { Box, BoxProps, Text, HStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideoSlash, faVolumeMute } from '@fortawesome/free-solid-svg-icons';
import { villagerFamilySelector } from '../state/villagers';
import { Emote } from '../components/Emote';

interface VillagerProps extends BoxProps {
  villagerId: string;
  pixelSize: number;
}

interface NameHeaderProps extends BoxProps {
  emoteActive: boolean;
  name: string;
  isMicrophoneEnabled: boolean;
  isCameraEnabled: boolean;
  status: string;
}

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const NameHeader = ({
  emoteActive,
  name,
  isMicrophoneEnabled,
  isCameraEnabled,
  status,
}: NameHeaderProps) => {
  const getStatusColor = () => {
    if (status === 'online') {
      return '#42E583';
    }
    if (status === 'blurred') {
      return '#FFA620';
    }
    return '#000';
  };
  return (
    <Box position="relative" userSelect="none">
      <Box position="fixed" top="-10px">
        <Box
          opacity={emoteActive ? 0.2 : 0.9}
          position="relative"
          borderRadius="6px"
          backgroundColor="#3F3F3F"
          marginLeft="calc(-50% + 16px)"
          width="100%"
          px="6px"
        >
          <HStack spacing="4px">
            <Box
              width="8px"
              height="8px"
              borderRadius="4px"
              backgroundColor={`${getStatusColor()}`}
            />
            <Text whiteSpace="nowrap" fontSize="14px" color="white">
              {capitalizeFirstLetter(name)}
            </Text>
            {!isMicrophoneEnabled && (
              <Text paddingLeft="4px" fontSize="14px">
                <FontAwesomeIcon
                  fontSize="3px"
                  color="#EA3535"
                  icon={faVolumeMute}
                />
              </Text>
            )}
            {!isCameraEnabled && (
              <Text paddingLeft="2px" fontSize="12px">
                <FontAwesomeIcon
                  fontSize="3px"
                  color="#EA3535"
                  icon={faVideoSlash}
                />
              </Text>
            )}
          </HStack>
        </Box>
      </Box>
    </Box>
  );
};

const Villager = ({ villagerId, pixelSize }: VillagerProps) => {
  const [villager] = useRecoilState(villagerFamilySelector(villagerId)) as [
    Villager,
    () => void
  ];

  const [gridCell, setGridCell] = useState<number>(pixelSize * 16);
  const [skin, setSkin] = useState<Skin>();
  const [isWalking, setIsWalking] = useState<boolean>(false);

  const [emoteActive, setEmoteActive] = useState<boolean>(false);

  const handleWalk = () => {
    setIsWalking(true);
    setTimeout(() => {
      setIsWalking(false);
    }, 400);
  };

  const handleEmote = () => {
    if (!emoteActive) {
      setEmoteActive(true);
      setTimeout(() => {
        setEmoteActive(false);
      }, 3000);
    }
  };

  useEffect(() => {
    handleWalk();
  }, [
    villager.data.position.coordinates.x,
    villager.data.position.coordinates.y,
  ]);

  useEffect(() => {
    setGridCell(pixelSize * 16);
  }, [pixelSize]);

  useEffect(() => {
    setSkin({
      idle: `/assets/characters/${villager.data.sprite}_idle_anim_32x32.png`,
      walking: `/assets/characters/${villager.data.sprite}_run_32x32.png`,
    });
  }, [villager.data.sprite]);

  useEffect(() => {
    if (villager.data.emoteKey) {
      handleEmote();
    }
  }, [villager.data.emoteKey]);

  const Move = (_x: number, _y: number, _gridCell: number) =>
    `translate3d(${_x * _gridCell}px, ${_y * _gridCell}px, 0)`;

  return (
    <Box
      position="absolute"
      width={`${gridCell * 1}px`}
      height={`${gridCell * 2}px`}
      marginTop={`-${gridCell * 1.1}px`}
      zIndex={villager.data.position.coordinates.y}
    >
      <Box
        transform={Move(
          villager.data.position.coordinates.x,
          villager.data.position.coordinates.y,
          gridCell
        )}
        transition="linear 0.2s"
      >
        <NameHeader
          emoteActive={emoteActive}
          name={villager.name}
          isMicrophoneEnabled={
            villager.media && villager.media.isMicrophoneEnabled
          }
          isCameraEnabled={villager.media && villager.media.isCameraEnabled}
          status={villager.data.status}
        />
      </Box>
      {emoteActive && (
        <Box
          position="absolute"
          opacity="0.9"
          transform={Move(
            villager.data.position.coordinates.x,
            villager.data.position.coordinates.y,
            gridCell
          )}
          transition="linear 0.2s"
          marginTop={`-${gridCell * 1.1}px`}
        >
          <Emote emoteKey={villager.data.emoteKey} />
        </Box>
      )}
      <Box
        width={`${gridCell * 1}px`}
        height={`${gridCell * 2}px`}
        overflow="hidden"
        transform={Move(
          villager.data.position.coordinates.x,
          villager.data.position.coordinates.y,
          gridCell
        )}
        transition="linear 0.2s"
      >
        <Box
          animation={`walkAnimation_${villager.data.position.facing} ${
            isWalking ? '0.6s' : '1.2s'
          } steps(6) infinite`}
          background={`url(${
            isWalking ? skin?.walking : skin?.idle
          }) no-repeat no-repeat`}
          backgroundSize="100%"
          width={`${(pixelSize * 768) / 2}px`} // pixelsize * image width divided in half because image is 32x32 resolution per unit, while map is only 16 atm
          height={`${(pixelSize * 64) / 2}px`} // pixelsize * image width divided in half because image is 32x32 resolution per unit, while map is only 16 atm
          style={{
            imageRendering: 'pixelated',
          }}
        />
      </Box>
    </Box>
  );
};

export default Villager;
