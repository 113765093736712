import {
  Box,
  HStack,
  VStack,
  Text,
  Button,
  Image,
  BoxProps,
  Spinner,
  Center,
  IconButton,
} from '@chakra-ui/react';
import { ReactNode, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useAuthContext } from '../context/AuthContextProvider';

interface NavButtonLabel extends BoxProps {
  label: string;
  goToRoute?: string;
}

const NavButton = ({ label, goToRoute }: NavButtonLabel) => {
  const { push } = useHistory();
  const route = () => {
    if (goToRoute) {
      push(`/${goToRoute}`);
    } else {
      push('/');
    }
  };

  return (
    <Box
      cursor="pointer"
      fontSize="18px"
      _hover={{ textDecoration: 'underline', fontWeight: '500' }}
      onClick={() => route()}
    >
      <Text>{label}</Text>
    </Box>
  );
};

export const Decorator = ({ children }: { children: ReactNode }) => {
  const { push } = useHistory();
  const [menuIsExpanded, toggleMenuExpand] = useState(false);
  const logoUrl = '/assets/logo/logo.png';

  const {
    loginWithGoogle,
    signOutFromGoogle,
    loggedInUser,
    isLoadingAuthState,
  } = useAuthContext();
  const goToHome = () => {
    push(`/`);
  };

  return (
    <Box height="100%">
      {isLoadingAuthState && (
        <Center height="100%">
          <Spinner />
        </Center>
      )}
      {!isLoadingAuthState && (
        <Box height="100%" animation="fadein 0.05s">
          <Box height="0">
            <HStack justify="space-between" py="12px" userSelect="none">
              <HStack
                spacing="16px"
                onClick={() => goToHome()}
                cursor="pointer"
              >
                <Image
                  src={logoUrl}
                  width={{ base: '26px', md: '32px' }}
                  height={{ base: '26px', md: '32px' }}
                />
                <Text
                  fontSize={{ base: '18px', md: '22px' }}
                  textColor="#1B3A57"
                >
                  Hurv
                </Text>
              </HStack>
              <HStack
                color="#1B3A57"
                spacing="12px"
                display={{ base: 'none', md: 'flex' }}
              >
                <NavButton label="Download" />
                <NavButton label="Pricing" goToRoute="plans" />
                <NavButton label="Company" />
                <NavButton label="Support" />
              </HStack>
              <Box>
                {loggedInUser && (
                  <HStack>
                    <HStack cursor="default">
                      <Image
                        src={loggedInUser.imageUrl}
                        width={{ base: '30px', md: '38px' }}
                        height={{ base: '30px', md: '38px' }}
                        borderRadius="50%"
                      />
                      <Text fontSize={{ base: '14px', md: '18px' }}>
                        {loggedInUser.name}
                      </Text>
                    </HStack>
                    <Box position="relative">
                      <IconButton
                        onClick={() => toggleMenuExpand((prev) => !prev)}
                        aria-label="Expand menu"
                        variant="ghost"
                        icon={
                          <FontAwesomeIcon
                            icon={menuIsExpanded ? faChevronUp : faChevronDown}
                          />
                        }
                      />
                      {menuIsExpanded && (
                        <Box
                          position="absolute"
                          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                          border="1px solid #9E9E9E"
                          borderRadius="12px"
                          backgroundColor="white"
                          top="52px"
                          right="0"
                        >
                          <VStack spacing="0" px="24px" paddingTop="12px">
                            <Image
                              src={loggedInUser.imageUrl}
                              width={{ base: '30px', md: '62px' }}
                              height={{ base: '30px', md: '62px' }}
                              marginTop="12px"
                              borderRadius="50%"
                            />
                            <Text
                              paddingTop="12px"
                              whiteSpace="nowrap"
                              fontSize={{ base: '14px', md: '18px' }}
                            >
                              {loggedInUser.name}
                            </Text>
                            <Text
                              whiteSpace="nowrap"
                              fontSize={{ base: '11px', md: '14px' }}
                            >
                              {loggedInUser.email}
                            </Text>
                          </VStack>
                          <Center p="24px">
                            <Button
                              variant="outline"
                              height={{ base: '30px', md: '38px' }}
                              fontSize="22px"
                              textColor="#1B3A57"
                              onClick={() => {
                                signOutFromGoogle();
                                toggleMenuExpand(false);
                              }}
                            >
                              <Text
                                fontSize={{ base: '11px', md: '14px' }}
                                fontWeight="700"
                              >
                                Logout
                              </Text>
                            </Button>
                          </Center>
                        </Box>
                      )}
                    </Box>
                  </HStack>
                )}
                {!loggedInUser && (
                  <Button
                    height={{ base: '30px', md: '38px' }}
                    fontSize="22px"
                    textColor="#fff"
                    background="#1B3A57"
                    borderRadius="5px"
                    _hover={{ bg: '#77a6b5' }}
                    onClick={loginWithGoogle}
                  >
                    <Text
                      fontSize={{ base: '11px', md: '14px' }}
                      fontWeight="400"
                    >
                      Log in
                    </Text>
                  </Button>
                )}
              </Box>
            </HStack>
          </Box>
          <Box paddingTop="64px" height="100%" zIndex="1">
            {children}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Decorator;
