import { Box, Text, BoxProps } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

interface NavButtonLabel extends BoxProps {
  label: string;
  goToRoute?: string;
}

export const NavLink = ({ label, goToRoute, ...rest }: NavButtonLabel) => {
  const { push } = useHistory();
  const route = () => {
    if (goToRoute) {
      push(`/${goToRoute}`);
    } else {
      push('/');
    }
  };

  return (
    <Box
      {...rest}
      cursor="pointer"
      _hover={{ textDecoration: 'underline', fontWeight: '700' }}
      onClick={() => route()}
    >
      <Text>{label}</Text>
    </Box>
  );
};

export default NavLink;
