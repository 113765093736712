import {
  Box,
  Image,
  HStack,
  Text,
  Button,
  Center,
  BoxProps,
  InputGroup,
  InputLeftAddon,
  Input,
  Switch,
  Checkbox,
  IconButton,
} from '@chakra-ui/react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import useDebounce from '../hooks/useDebounce';
import { useAuthContext } from '../context/AuthContextProvider';
import { NavLink } from '../components/NavLink';

interface DotProps extends BoxProps {
  text: string;
  isActive?: boolean;
  isLastChild?: boolean;
}

const Dot = ({ text, isActive, isLastChild }: DotProps) => {
  return (
    <Box
      position="relative"
      width={isLastChild ? '25px' : '100%'}
      _after={{
        content: '""',
        width: !isLastChild ? 'calc(100% - 24px)' : '0',
        borderBottom: isActive ? 'solid 2px #1B3A57' : 'solid 2px #49A7FF',
        position: 'absolute',
        left: '24px',
        top: 'calc(50% + -1px)',
      }}
    >
      <Box
        width="24px"
        height="24px"
        backgroundColor={isActive ? '#1B3A57' : '#FFF'}
        borderRadius="50%"
        border={!isActive ? '1px solid #49A7FF' : ''}
        zIndex={2}
      >
        <Center height="100%" mb="6px">
          <Box
            width="6px"
            height="6px"
            backgroundColor={isActive ? '#FFF' : '#49A7FF'}
            borderRadius="50%"
          />
        </Center>
        <Center>
          <Box position="relative" width="0px" height="0">
            <Text
              fontSize="12px"
              color={isActive ? '#1B3A57' : '#9E9E9E'}
              left="-50px"
              position="absolute"
              width="100px"
              align="center"
            >
              {text}
            </Text>
          </Box>
        </Center>
      </Box>
    </Box>
  );
};

interface ProgressBarProps extends BoxProps {
  step: number;
  isPrivate: boolean;
  goToStep: (step: number) => void;
}

const ProgressBar = ({
  step,
  isPrivate,
  goToStep,
  ...rest
}: ProgressBarProps) => {
  return (
    <Center {...rest}>
      <Box width="100%" marginBottom="20px" mx="24%">
        <HStack spacing={0} justify="space-between">
          <Dot
            onClick={() => console.log('STEP')} // TODO: fix
            text="Create space"
            isActive={step >= 0}
          />
          {isPrivate && (
            <Dot
              onClick={() => console.log('STEP')} // TODO: fix
              text="Invite members"
              isActive={step >= 1}
            />
          )}
          <Dot
            onClick={() => console.log('STEP')} // TODO: fix
            text="Complete"
            isActive={step >= 2}
            isLastChild
          />
        </HStack>
      </Box>
    </Center>
  );
};

const SelectRoomName = (props: any) => {
  const [roomNameIsTaken, setRoomNameIsTaken] =
    useState<boolean | undefined>(undefined);
  const [roomName, setRoomName] = useState<string>('');
  const debouncedRoomName = useDebounce<string | undefined>(roomName, 800);
  const [termsAreAccepted, setTermsAreAccepted] = useState(false);
  const [roomIsPrivate, setRoomIsPrivate] = useState(true);

  const sanetizeRoomName = (string: string) => {
    let sanetizedString = string.replace(/\s/g, '-');
    sanetizedString = sanetizedString.replace(/[^a-zA-Z0-9-_]/g, '');
    sanetizedString = sanetizedString.toLocaleLowerCase();
    return sanetizedString;
  };

  useEffect(() => {
    setRoomNameIsTaken(undefined);
    if (debouncedRoomName !== '') {
      fetch(
        `${process.env.REACT_APP_API_URL}api/room-exists?room=${debouncedRoomName}`,
        {
          method: 'GET',
          cache: 'no-cache',
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          if (res.status === 401) {
            return res.json();
          }
          throw new Error(res.statusText);
        })
        .then((data) => setRoomNameIsTaken(data.roomExists))
        .catch((error) => {
          console.error(error);
        });
    }
  }, [debouncedRoomName]);

  return (
    <Box>
      <Text color="#000" fontSize="28px" fontWeight="700" pb="24px">
        Create your own virtual space
      </Text>
      <Text color="#000" fontSize="14px" pb="6px">
        Your space needs a name
      </Text>
      <InputGroup height="48px" mb="12px">
        <InputLeftAddon height="48px">
          <Text fontSize="16px" color="#9E9E9E">
            hurv.com/
          </Text>
        </InputLeftAddon>
        <Input
          isInvalid={roomNameIsTaken}
          height="48px"
          fontSize="16px"
          placeholder="my-space"
          color="#1B3A57"
          fontWeight={roomName === '' ? 400 : 700}
          value={roomName}
          onChange={(e) => setRoomName(sanetizeRoomName(e.target.value))}
        />
      </InputGroup>
      {roomNameIsTaken && (
        <Text color="#EA3535" fontSize="11px">
          This room is not available.
        </Text>
      )}
      <HStack mb="12px">
        <Text color="#000" fontSize="16px" paddingRight="6px">
          Private space?
        </Text>
        <Switch
          size="md"
          colorScheme="blue"
          defaultChecked={roomIsPrivate}
          onChange={(e) => {
            setRoomIsPrivate(e.target.checked);
            props.onChangeRoomIsPrivate(e.target.checked);
          }}
        />
      </HStack>

      <HStack marginRight="20%">
        <Checkbox
          size="lg"
          colorScheme="blue"
          defaultIsChecked={termsAreAccepted}
          onChange={(e) => setTermsAreAccepted(e.target.checked)}
        />
        <Box color="#333333" fontSize="12px" paddingRight="6px">
          By creating a room, you agree to our{' '}
          <Text
            cursor="pointer"
            fontWeight="700"
            display="inline-block"
            color="#1B3A57"
            _hover={{ textDecoration: 'underline' }}
          >
            Privacy policy
          </Text>
          and{' '}
          <Text
            cursor="pointer"
            fontWeight="700"
            display="inline-block"
            color="#1B3A57"
            _hover={{ textDecoration: 'underline' }}
          >
            Terms & Conditions
          </Text>
        </Box>
      </HStack>

      <Button
        onClick={() => props.onProgress(debouncedRoomName)}
        height={{ base: '38px', md: '48px' }}
        mt="24px"
        textColor="#fff"
        background="#1B3A57"
        _hover={{ bg: '#77a6b5' }}
        disabled={
          roomNameIsTaken ||
          !debouncedRoomName ||
          debouncedRoomName === '' ||
          roomNameIsTaken === undefined ||
          !termsAreAccepted
        }
      >
        <HStack>
          <Text px="12px" color="white" fontSize="16px">
            Next
          </Text>
        </HStack>
      </Button>
    </Box>
  );
};

interface EmailListProps extends BoxProps {
  emailList: string[];
  owner: string;
  removeEmail: (email: string) => void;
}

const EmailList = ({
  emailList,
  owner,
  removeEmail,
  ...rest
}: EmailListProps) => {
  const removeFromEmailList = (email: string) => removeEmail(email);

  return (
    <Box py="6px" px="6px" border="1px solid #1B3A57" borderRadius="5px">
      <Box
        width="100%"
        height="100px"
        overflowY="scroll"
        css={{
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#1B3A57',
            borderRadius: '24px',
          },
        }}
        {...rest}
      >
        <HStack mx="6px" justify="space-between" key={owner}>
          <Text fontSize="14px" color="#1B3A57">
            {owner}
          </Text>
          <Text fontSize="14px" color="#9E9E9E">
            owner
          </Text>
        </HStack>
        {emailList.map((email) => {
          return (
            <HStack mx="6px" justify="space-between" key={email}>
              <Text fontSize="14px" color="#1B3A57">
                {email}
              </Text>
              <IconButton
                onClick={() => removeFromEmailList(email)}
                aria-label="Remove email"
                color="#1B3A57"
                variant="ghost"
                size="xs"
                icon={<FontAwesomeIcon icon={faTimes} />}
              />
            </HStack>
          );
        })}
      </Box>
    </Box>
  );
};

const InvitePeople = (props: any) => {
  const [emailInput, setEmailInput] = useState<string>('');
  const [emailList, setEmailList] = useState<string[]>([]);
  const { loggedInUser } = useAuthContext();
  const ownerEmail = loggedInUser?.email || '';

  const addEmailToList = () => {
    if (emailInput !== '') {
      setEmailList((prevState) => [emailInput, ...prevState]);
      setEmailInput('');
    }
  };

  const removeEmailfromList = (emailToRemove: string) => {
    setEmailList((prevState) => prevState.filter((e) => e !== emailToRemove));
    setEmailInput('');
  };

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    addEmailToList();
  };

  return (
    <Box>
      <Text color="#000" fontSize="28px" fontWeight="700" pb="24px">
        Invite people
      </Text>
      <Box as="form" onSubmit={handleSubmit}>
        <Input
          mb="6px"
          height="48px"
          fontSize="16px"
          placeholder="Email"
          color="#1B3A57"
          value={emailInput}
          onChange={(e) => setEmailInput(e.target.value)}
        />
      </Box>

      <Button
        onClick={() => addEmailToList()}
        height={{ base: '31px', md: '38px' }}
        width="100%"
        textColor="#fff"
        background="#88CF7C"
        mb="16px"
        _hover={{ bg: '#99EB8C' }}
        disabled={emailInput === ''}
      >
        <Text
          px="12px"
          color={emailInput === '' ? 'black' : 'white'}
          fontSize="12px"
          fontWeight="700"
        >
          {emailInput === ''
            ? 'Enter a email address to invite'
            : `Invite ${emailInput} to lounge`}
        </Text>
      </Button>

      <EmailList
        emailList={emailList}
        owner={ownerEmail}
        removeEmail={(emailToRemove) => removeEmailfromList(emailToRemove)}
      />

      <Button
        onClick={() => props.onProgress(emailList)}
        height={{ base: '38px', md: '48px' }}
        mt="24px"
        textColor="#fff"
        background="#1B3A57"
        _hover={{ bg: '#77a6b5' }}
      >
        <Text px="12px" color="white" fontSize="16px">
          Next
        </Text>
      </Button>
    </Box>
  );
};

const LoginwithGoogle = () => {
  const { loginWithGoogle } = useAuthContext();

  return (
    <Box>
      <Text color="#000" fontSize="28px" fontWeight="700" pb="24px">
        Create your own virtual space
      </Text>

      <Button
        onClick={() => loginWithGoogle()}
        height={{ base: '38px', md: '48px' }}
        width="100%"
        border="1px solid #000"
        textColor="#000"
        background="#FFF"
        _hover={{ bg: '#E9E9E9' }}
        mb="120px"
      >
        <HStack spacing="12px">
          <Image src="/assets/g_logo.png" boxSize="26px" objectFit="cover" />
          <Text color="black" fontSize="16px" fontWeight="700">
            Continue with Google
          </Text>
        </HStack>
      </Button>
      <NavLink
        label="Create a free public room instead?"
        goToRoute="new-room"
        bottom="12px"
        right="12px"
        position="absolute"
        fontSize="16px"
        color="#1B3A57"
        cursor="pointer"
      />
    </Box>
  );
};

interface CheckoutProps extends BoxProps {
  roomName: string;
  emailList: string[];
  isPrivate: boolean;
}

const Checkout = ({ roomName, emailList, isPrivate }: CheckoutProps) => {
  const { idToken } = useAuthContext();
  const { push } = useHistory();

  const goToLobby = () => {
    push(`/lobby/${roomName}`);
  };

  const createRoom = () => {
    fetch(`${process.env.REACT_APP_API_URL}api/claim-room`, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken || 'unauthenticated'}`,
      },
      body: JSON.stringify({
        roomName,
        guestList: emailList,
        isPrivate,
        licenseCode: 100,
        invites: [
          {
            code: uuid(),
            expires: Date.now() + 1000 * 60 * 60 * 24 * 3, // 3 Days
            grantMembership: false,
            uses: 100,
          },
        ],
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        if (res.status === 401) {
          return res.json();
        }
        throw new Error(res.statusText);
      })
      .then((data) => console.log(data))
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    createRoom();
  }, []);

  return (
    <Box mb="80px">
      <Text color="#000" fontSize="28px" fontWeight="700" pb="12px">
        {`${roomName} is now yours 🎉`}
      </Text>
      <Text color="#000" fontSize="16px" pb="6px">
        {`Your domain name is hurv.com/${roomName}, and it is a ${
          isPrivate ? 'private' : 'public'
        } room${
          isPrivate
            ? ` with ${emailList.length + 1} user${
                emailList.length + 1 > 1 ? 's' : ''
              }.`
            : '.'
        }`}
      </Text>
      {isPrivate && (
        <Text color="#000" fontSize="16px">
          You may also add more users later.
        </Text>
      )}
      <Button
        mt="36px"
        height={{ base: '38px', md: '48px' }}
        textColor="#fff"
        background="#1B3A57"
        _hover={{ bg: '#77a6b5' }}
        onClick={() => goToLobby()}
      >
        <Text>Enter {roomName}</Text>
      </Button>
    </Box>
  );
};

export const Order = () => {
  const [step, setStep] = useState(0);
  const { loggedInUser } = useAuthContext();
  const [roomName, setRoomName] = useState('');
  const [roomIsPrivate, setRoomIsPrivate] = useState(true);
  const [emailList, setEmailList] = useState<string[]>([]);
  const { push } = useHistory();

  const goToHome = () => {
    push(`/`);
  };

  useEffect(() => {
    if (!loggedInUser) {
      setStep(0);
    }
  }, [loggedInUser]);

  return (
    <Box height="100%" backgroundColor="rgba(0,0,0,0.6)">
      <Image
        src="/assets/office-small.png"
        boxSize="100%"
        objectFit="cover"
        style={{
          imageRendering: 'pixelated',
        }}
        filter="blur(1px)"
        position="fixed"
        zIndex="-1"
      />
      <Center height="100%">
        <HStack animation="rollUp 0.4s" spacing={0}>
          <Box
            border="1px solid #AAAAAA"
            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
            backgroundColor="#FFF"
            width="630px"
            height="560px"
            borderRadius="12px 0 0 12px"
            px="18px"
            py="12px"
            position="relative"
          >
            <Text
              onClick={() => goToHome()}
              cursor="pointer"
              color="#1B3A57"
              fontSize="22px"
            >
              Hurv
            </Text>
            <ProgressBar
              goToStep={(childStep) => setStep(childStep)}
              step={step}
              isPrivate={roomIsPrivate}
              pt="36px"
            />
            <Center height="calc(100% - 113px)">
              {loggedInUser && (
                <Box width="400px">
                  {step === 0 && (
                    <SelectRoomName
                      onProgress={(childRoomName: string) => {
                        setRoomName(childRoomName);
                        if (!roomIsPrivate) {
                          setStep(2);
                        } else {
                          setStep(1);
                        }
                      }}
                      onChangeRoomIsPrivate={(childRoomIsPrivate: boolean) => {
                        setRoomIsPrivate(childRoomIsPrivate);
                      }}
                    />
                  )}
                  {step === 1 && (
                    <InvitePeople
                      onProgress={(childEmailList: string[]) => {
                        setEmailList(childEmailList);
                        setStep(2);
                      }}
                    />
                  )}
                  {step === 2 && (
                    <Checkout
                      roomName={roomName}
                      emailList={emailList}
                      isPrivate={roomIsPrivate}
                    />
                  )}
                </Box>
              )}
              {!loggedInUser && (
                <Box width="400px">
                  <LoginwithGoogle />
                </Box>
              )}
            </Center>
          </Box>
          <Box
            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
            width="313px"
            height="560px"
            overflow="hidden"
            borderRadius="0 12px 12px 0"
          >
            <Image
              src="/assets/user_in_empty_office.png"
              boxSize="570px"
              objectFit="cover"
              style={{
                imageRendering: 'pixelated',
              }}
            />
          </Box>
        </HStack>
      </Center>
    </Box>
  );
};

export default Order;
