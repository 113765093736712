import { Box, BoxProps } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThumbsUp,
  faThumbsDown,
  faHandSparkles,
  faLaughBeam,
  faSurprise,
} from '@fortawesome/free-solid-svg-icons';

const emotes = [
  {
    key: 'wave',
    id: faHandSparkles,
  },
  {
    key: 'smile',
    id: faLaughBeam,
  },
  {
    key: 'surprised',
    id: faSurprise,
  },
  {
    key: 'like',
    id: faThumbsUp,
  },
  {
    key: 'dislike',
    id: faThumbsDown,
  },
];

interface EmoteProps extends BoxProps {
  emoteKey: string | undefined;
}

export const Emote = ({ emoteKey }: EmoteProps) => {
  const getEmoteIcon = () => {
    const emote = emotes.find((item) => item.key === emoteKey);
    if (emote) {
      return emote.id;
    }
    return faThumbsUp;
  };

  return (
    <Box position="fixed">
      <Box
        position="relative"
        width="50px"
        height="40px"
        borderRadius="25px"
        backgroundColor="#3F3F3F"
        fontSize="25px"
        textAlign="center"
        _after={{
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: '50%',
          width: 0,
          height: 0,
          border: '10px solid transparent',
          borderTopColor: '#3F3F3F',
          borderBottom: 0,
          borderLeft: 0,
          marginLeft: '-8px',
          marginBottom: '-10px',
        }}
      >
        <FontAwesomeIcon icon={getEmoteIcon()} color="#FFF" />
      </Box>
    </Box>
  );
};

export default Emote;
