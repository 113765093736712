import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  heroIsWalkingSelector,
  heroPositionSelector,
} from '../state/hero/selectors';
import {
  joystickDirectionSelector,
  mapPanSelector,
  mapSelector,
} from '../state/room';

const directions: { [key: string]: string } = {
  ArrowUp: 'up',
  ArrowDown: 'down',
  ArrowLeft: 'left',
  ArrowRight: 'right',
};

const useWalk = () => {
  // Handle movement logic
  const [canWalk, setcanWalk] = useState<boolean>(true);
  const [heldDirectionKeys, setHeldDirectionKeys] = useState<string[]>([]);
  const setMapPanChoordinates = useSetRecoilState(mapPanSelector);
  const setHeroPosition = useSetRecoilState(heroPositionSelector);
  const map = useRecoilValue(mapSelector);
  const [isWalking, setIsWalking] = useRecoilState(heroIsWalkingSelector);
  const hasJoystickDirection = useRecoilValue(joystickDirectionSelector);

  const resetMapPanToHero = () => {
    setMapPanChoordinates(undefined);
  };

  const canMove = (
    deltaX: number,
    deltaY: number,
    prevX: number,
    prevY: number
  ) => {
    const isNotOutOfBounds =
      prevY + deltaY >= 0 &&
      prevY + deltaY < map.vCells &&
      prevX + deltaX >= 0 &&
      prevX + deltaX < map.hCells;
    if (isNotOutOfBounds) {
      const isNotWall = map.walls[prevY + deltaY][prevX + deltaX] !== 1;
      return isNotWall;
    }
    return false;
  };

  const move = (deltaX: number, deltaY: number, facing: string) => {
    resetMapPanToHero();
    setHeroPosition(({ coordinates: prevCoordinates }) => {
      if (canMove(deltaX, deltaY, prevCoordinates.x, prevCoordinates.y)) {
        return {
          coordinates: {
            x: prevCoordinates.x + deltaX,
            y: prevCoordinates.y + deltaY,
          },
          facing,
        };
      }
      return { coordinates: prevCoordinates, facing };
    });
  };

  const keyIsAlreadyHeld = (key: string) =>
    !heldDirectionKeys.find((el) => el === key);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (directions[e.key] && keyIsAlreadyHeld(directions[e.key])) {
      setHeldDirectionKeys((prev) => [...prev, directions[e.key]]);
    }
  };

  const handleKeyUp = (e: KeyboardEvent) => {
    setHeldDirectionKeys((prev) =>
      prev.filter((el) => el !== directions[e.key])
    );
  };

  const resetAfterStep = () => {
    setIsWalking(false);
    setcanWalk(true);
  };

  const step = () => {
    let lastKeyPressed = heldDirectionKeys[heldDirectionKeys.length - 1];
    if (hasJoystickDirection) {
      lastKeyPressed = hasJoystickDirection;
    }
    switch (lastKeyPressed) {
      case 'down':
        move(0, 1, lastKeyPressed);
        break;
      case 'up':
        move(0, -1, lastKeyPressed);
        break;
      case 'left':
        move(-1, 0, lastKeyPressed);
        break;
      case 'right':
        move(1, 0, lastKeyPressed);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if ((canWalk && heldDirectionKeys.length > 0) || hasJoystickDirection) {
      setIsWalking(true);
      setcanWalk(false);
    }
  }, [heldDirectionKeys, canWalk, hasJoystickDirection]);

  useEffect(() => {
    if (isWalking && !canWalk) {
      step();
      setTimeout(() => resetAfterStep(), 180);
    }
  }, [isWalking]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [heldDirectionKeys]);
  return null;
};

export default useWalk;
