import { Box, BoxProps, VStack } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThumbsUp,
  faThumbsDown,
  faHandSparkles,
  faLaughBeam,
  faSurprise,
  faSmile,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { hurvClient } from '../../api';
import { heroEmoteSelector } from '../../state/hero/selectors';

const emotes = [
  {
    key: 'wave',
    id: faHandSparkles,
  },
  {
    key: 'smile',
    id: faLaughBeam,
  },
  {
    key: 'surprised',
    id: faSurprise,
  },
  {
    key: 'like',
    id: faThumbsUp,
  },
  {
    key: 'dislike',
    id: faThumbsDown,
  },
];

const Emotes = (props: any) => {
  const handleClick = (emote: any) => {
    props.onEmote(emote);
  };

  return (
    <VStack spacing="0px">
      {emotes.map((emote) => (
        <Box
          key={emote.key}
          onClick={() => handleClick(emote.key)}
          margin="0 auto"
          fontSize="30px"
          cursor="pointer"
        >
          <FontAwesomeIcon icon={emote.id} color="#1B3A57" />
        </Box>
      ))}
    </VStack>
  );
};

export const EmoteButton = (props: BoxProps) => {
  const [expanded, setExpanded] = useState(false);
  const setEmoteKey = useSetRecoilState(heroEmoteSelector);

  const clickedEmote = (emote: any) => {
    hurvClient.userEmote(emote);
    setEmoteKey(emote);
    setExpanded(false);
  };

  return (
    <Box
      width="50px"
      height={expanded ? '300px' : '50px'}
      backgroundColor="#FFF"
      borderRadius="25px"
      display="grid"
      placeItems="center"
      border="1px solid #1B3A57"
      transition=".1s"
      {...props}
    >
      {!expanded && (
        <Box
          onClick={() => setExpanded(!expanded)}
          marginTop="-18px"
          fontSize="35px"
          width="35px"
          height="35px"
          color="#1B3A57"
          cursor="pointer"
        >
          <FontAwesomeIcon icon={faSmile} />
        </Box>
      )}
      {expanded && (
        <VStack>
          <Box
            onClick={() => setExpanded(!expanded)}
            color="#3F3F3F"
            fontSize="35px"
            cursor="pointer"
          >
            <FontAwesomeIcon icon={faTimes} />
          </Box>
          <Emotes onEmote={clickedEmote} />
        </VStack>
      )}
    </Box>
  );
};

export default EmoteButton;
