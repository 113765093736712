import { Box } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { socketIdToPeerId, usePeerContext } from '../../context/PeerContext';
import { maximizedCallIdSelector } from '../../state/calls';
import { MediaFrame } from './MediaFrame';

export const MaximisedMedia = () => {
  const callId = useRecoilValue(maximizedCallIdSelector) || '';
  const { peerCalls } = usePeerContext();
  const { camera } = peerCalls[socketIdToPeerId(callId)] || {};

  return (
    <Box width="100%" height="100%">
      <MediaFrame
        callId={callId}
        peerCall={camera}
        width="100%"
        height="100%"
      />
    </Box>
  );
};

export default MaximisedMedia;
