import { useEffect, useState } from 'react';
import ReactNipple from 'react-nipple';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  Box,
  Flex,
  Center,
  Spinner,
  Text,
  Spacer,
  VStack,
  BoxProps,
  FlexProps,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandAlt } from '@fortawesome/free-solid-svg-icons';
import {
  joystickDirectionSelector,
  mapPanSelector,
  maximisedState,
  maxizedSelector,
  roomIdSelector,
  roomIsLoadingSelector,
} from '../state/room';
import {
  heroIdSelector,
  heroNameSelector,
  heroPositionSelector,
  heroSpriteSelector,
  heroStatusSelector,
} from '../state/hero';
import {
  maximizedCallIdSelector,
  selfIsCameraEnabledSelector,
  selfIsMicrophoneEnabledSelector,
} from '../state/calls';
import { hurvClient } from '../api';
import { PageContainer } from '../components/PageContainer';
import { HurvHeader } from '../components/HurvHeader';
import { Frame } from '../game/Frame';
import { Chat } from '../components/chat/Chat';
import { ChatButton } from '../components/buttons/ChatButton';
import { ConnectedMedia } from '../components/ConnectedMedia';
import Camera from '../components/Camera';
import ScreenShare from '../components/ScreenShare';
import User from '../components/User';
import useVillagers from '../hooks/useVillagers';
import useCalls from '../hooks/useCalls';
import useChat from '../hooks/useChat';
import useRoom from '../hooks/useRoom';
import { MicrophoneButton } from '../components/buttons/MicrophoneButton';
import { CameraButton } from '../components/buttons/CameraButton';
import { ScreenShareButton } from '../components/buttons/ScreenShareButton';
import { MaximisedMediaGrid } from '../components/media/MaximisedMediaGrid';
import { MaximisedMedia } from '../components/media/MaximisedMedia';
import { chatIsOpenSelector } from '../state/chat';
import { Decorator } from '../components/Decorator';
import { usePeerContext } from '../context/PeerContext';
import { ConnectedVillagerList } from '../components/ConnectedVillagerList';
import { useAuthContext } from '../context/AuthContextProvider';
import { EmoteButton } from '../components/buttons/EmoteButton';
import { useNotifications } from '../hooks/useNotifications';
import { Intro } from '../components/tutorials/Intro';

const GridView = () => {
  const [maximizedMediaCallId, setMaximizedMediaCallId] = useRecoilState(
    maximizedCallIdSelector
  );
  const setShowVideoGrid = useSetRecoilState(maximisedState);

  return (
    <VStack p="12px" height="100%">
      <Flex width="100%" height="100%">
        <Box flex="1" paddingRight="12px">
          <Flex direction="column" height="100%">
            <Box width="100%" height="100%" flex="1">
              {!maximizedMediaCallId && <MaximisedMediaGrid />}
              {maximizedMediaCallId && <MaximisedMedia />}
            </Box>
            <Center margin="36px 0 24px 0">
              <MicrophoneButton />
              <CameraButton />
              <ScreenShareButton />
            </Center>
          </Flex>
        </Box>
        <Box height="100%" width="350px">
          <Box
            width="36px"
            height="36px"
            backgroundColor="#FFF"
            borderRadius="20px"
            border="1px solid #1B3A57"
            margin="12px"
            position="fixed"
            zIndex="1"
            cursor="pointer"
            onClick={() => {
              setShowVideoGrid((prev) => !prev);
              setMaximizedMediaCallId(undefined);
            }}
          >
            <Box margin="2px 0 0 8px" fontSize="19px">
              <FontAwesomeIcon color="#000" icon={faExpandAlt} />
            </Box>
          </Box>
          <Box
            borderRadius="12px"
            width="100%"
            height="250px"
            overflow="hidden"
            marginBottom="12px"
            border="1px solid #202020"
          >
            <Frame marginLeft="160px" marginTop="125px" />
          </Box>
          <Box
            borderRadius="12px"
            border="1px solid #202020"
            height="calc(100% - 262px)"
          >
            <Chat />
          </Box>
        </Box>
      </Flex>
    </VStack>
  );
};

const Joystick = (props: BoxProps) => {
  const setJoystickDirection = useSetRecoilState(joystickDirectionSelector);
  return (
    <Box {...props}>
      <ReactNipple
        options={{ mode: 'static', position: { top: '50%', left: '50%' } }}
        style={{
          width: 100,
          height: 100,
          position: 'relative',
        }}
        onDir={(evt: any, data: any) =>
          setJoystickDirection(data.direction.angle)
        }
        onEnd={() => setJoystickDirection(null)}
      />
    </Box>
  );
};

const Desktop = (props: FlexProps) => {
  return (
    <Flex {...props} p="12px" direction="column" height="100%" width="100%">
      <Flex justifyContent="space-between" width="100%">
        <ConnectedVillagerList zIndex="2" />
        <ConnectedMedia px="24px" pointerEvents="none" />
        <ChatButton flexShrink={0} zIndex="2" />
      </Flex>
      <Spacer />
      <Flex p="12px" justifyContent="space-between" width="100%">
        <Camera zIndex="2" width="220px" height="165px" />
        <User zIndex="2" />
        <ScreenShare zIndex="2" width="220px" height="165px" />
      </Flex>
    </Flex>
  );
};

const Mobile = (props: FlexProps) => {
  return (
    <Flex {...props} p="12px" direction="column" height="100%" width="100%">
      <Flex justifyContent="space-between" width="100%">
        <ConnectedVillagerList zIndex="2" />
        <ConnectedMedia px="24px" pointerEvents="none" />
        <VStack>
          <ChatButton flexShrink={0} zIndex="2" />
          <EmoteButton flexShrink={0} zIndex="2" />
        </VStack>
      </Flex>
      <Spacer />
      <Flex p="12px" justifyContent="space-between" width="100%">
        <Camera
          zIndex="2"
          marginTop="auto"
          height={{ base: '115px', sm: '150px' }}
          width={{ base: '150px', sm: '200px' }}
        />
        <Joystick m="12px" />
      </Flex>
    </Flex>
  );
};

export const Room = () => {
  const { peerServerConnection, closePeerServerConnection } = usePeerContext();
  const { idToken } = useAuthContext();
  const { push } = useHistory();
  const [hasValidParamsToConnect, setHasValidParamsToConnect] = useState(false);
  const { roomId } = useParams<{ roomId: string }>();
  const showVideoGrid = useRecoilValue(maxizedSelector);
  const [currentRoomId, setUrlRoomId] = useRecoilState(roomIdSelector);
  const [id, setId] = useRecoilState(heroIdSelector);
  const isLoading = useRecoilValue(roomIsLoadingSelector);
  const name = useRecoilValue(heroNameSelector);
  const status = useRecoilValue(heroStatusSelector);
  const sprite = useRecoilValue(heroSpriteSelector);
  const heroPositionState = useRecoilValue(heroPositionSelector);
  const isCameraEnabled = useRecoilValue(selfIsCameraEnabledSelector);
  const isMicrophoneEnabled = useRecoilValue(selfIsMicrophoneEnabledSelector);
  const chatIsExpanded = useRecoilValue(chatIsOpenSelector);
  const mapPan = useRecoilValue(mapPanSelector);
  const tutorialCompletion = sessionStorage.getItem(
    'MOVEMENT_CAMERA_SCREENSHARE_TUTORIAL_IS_COMPLETED'
  );

  const handleSocketConnected = () => {
    if (hurvClient.socket?.id) {
      setId(hurvClient.socket.id);
    }
  };

  const handleSocketDisconnected = () => {
    console.warn('Connection closed to socket instance');
    push(`/lobby/${roomId}`);
  };

  const handlePeerServerDisconnected = () => {
    console.warn('Connection closed to peer server instance');
    push(`/lobby/${roomId}`);
  };

  useEffect(() => {
    hurvClient.socket.on('connect', handleSocketConnected);
    hurvClient.socket.on('disconnect', handleSocketDisconnected);
    if (!hurvClient.socket.connected) {
      hurvClient.connect();
    }
    return () => {
      closePeerServerConnection();
      hurvClient.disconnect();
      hurvClient.socket.off('connect', handleSocketConnected);
      hurvClient.socket.off('disconnect', handleSocketDisconnected);
    };
  }, []);

  useEffect(() => {
    if (peerServerConnection) {
      peerServerConnection.on('disconnected', handlePeerServerDisconnected);
    }
    return () => {
      if (peerServerConnection) {
        peerServerConnection.off('disconnected', handlePeerServerDisconnected);
      }
    };
  }, [peerServerConnection]);

  useEffect(() => {
    if (
      !!roomId &&
      (!currentRoomId || currentRoomId !== roomId) &&
      hasValidParamsToConnect
    ) {
      setUrlRoomId(roomId);
    }
  }, [roomId, hasValidParamsToConnect]);

  useEffect(() => {
    if (!name || !sprite) {
      push(`/lobby/${roomId}`);
    } else {
      setHasValidParamsToConnect(true);
    }
  }, [name, sprite]);

  useEffect(() => {
    if (roomId && id && hasValidParamsToConnect) {
      if (!hurvClient.socket.connected) {
        hurvClient.socket.connect();
      } else {
        hurvClient.joinRoom({
          roomId,
          user: {
            id,
            name,
            data: {
              status,
              sprite,
              position: heroPositionState,
            },
            media: {
              isCameraEnabled,
              isMicrophoneEnabled,
            },
          },
          authToken: idToken || 'Unauthorized',
        });
      }
    }
  }, [roomId, id, hasValidParamsToConnect]);

  // Initialize Atoms
  useVillagers();
  useCalls();
  useChat();
  useRoom();
  useNotifications();

  return (
    <Box height="100%">
      {isLoading && (
        <PageContainer>
          <Decorator>
            <Center height="100%">
              <Spinner />
            </Center>
          </Decorator>
        </PageContainer>
      )}

      {!isLoading && (
        <Box
          backgroundColor={showVideoGrid ? '#1b1b1b' : '#16161d'}
          height="100%"
          width="100%"
          overflow="hidden"
        >
          {!(tutorialCompletion === 'completed') && <Intro />}
          {showVideoGrid && <GridView />}
          {!showVideoGrid && (
            <VStack height="100%" width="100%">
              <HurvHeader />
              <Desktop display={{ base: 'none', md: 'flex' }} />
              <Mobile display={{ base: 'flex', md: 'none' }} />

              <Box
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                top="-75px"
                right="0"
                left={chatIsExpanded ? '-380px' : '0'}
                transition="left 0.1s"
                position="fixed"
              >
                {mapPan && (
                  <Box
                    position="absolute"
                    bottom="50%"
                    zIndex="10000"
                    backgroundColor="#3F3F3F"
                    px="12px"
                    py="6px"
                    borderRadius="6px"
                    opacity="0.9"
                  >
                    <Text color="#FFF" fontSize="14px">
                      Move to reset camera
                    </Text>
                  </Box>
                )}
                <Frame />
              </Box>
            </VStack>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Room;
