import {
  Box,
  VStack,
  Text,
  Button,
  BoxProps,
  Center,
  HStack,
  Image,
} from '@chakra-ui/react';
import { useAuthContext } from '../../context/AuthContextProvider';

interface LobbyCardProps extends BoxProps {
  roomData: RoomData;
}

export const LobbyCardPrivateLoggedOut = ({ roomData }: LobbyCardProps) => {
  const { loginWithGoogle } = useAuthContext();
  const { isPrivate, roomName } = roomData;

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Box
      width="285px"
      height="395px"
      borderRadius="12px"
      border="1px solid #ACACAC"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      background="#FFFFFF"
      zIndex="1"
    >
      <Center height="100%">
        <VStack spacing="50px">
          <Box textAlign="center">
            <Text fontSize="14px" fontWeight="300" paddingBottom="2px">
              You are joining
            </Text>
            <Text fontSize="24px" fontWeight="400" paddingBottom="6px">
              {isPrivate ? capitalizeFirstLetter(roomName) : 'Public Room'}
            </Text>
          </Box>

          <Box px="48px" textAlign="center">
            <Text fontSize="14px">
              {capitalizeFirstLetter(roomName)} is a private space, please log
              in to enter.
            </Text>
          </Box>

          <Button
            onClick={() => loginWithGoogle()}
            height={{ base: '38px', md: '48px' }}
            border="1px solid #000"
            textColor="#000"
            background="#FFF"
            _hover={{ bg: '#E9E9E9' }}
            mb="120px"
          >
            <HStack spacing="12px">
              <Image
                src="/assets/g_logo.png"
                boxSize="26px"
                objectFit="cover"
              />
              <Text color="black" fontSize="16px" fontWeight="700">
                Log in with Google
              </Text>
            </HStack>
          </Button>
        </VStack>
      </Center>
    </Box>
  );
};

export default LobbyCardPrivateLoggedOut;
