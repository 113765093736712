import { selector } from 'recoil';
import {
  hasAllowedCameraState,
  hasAllowedMicrophoneState,
  hasWebRTCSupportState,
  hasConnectedCameraState,
  hasConnectedMicrophoneState,
  hasFinishedSettingPermissionsState,
} from './atoms';

export const hasAllowedCameraSelector = selector<boolean | undefined>({
  key: 'has-allowed-camera',
  set: ({ set }, hasAllowedCamera) =>
    set(hasAllowedCameraState, hasAllowedCamera),
  get: ({ get }) => get(hasAllowedCameraState),
});

export const hasAllowedMicrophoneSelector = selector<boolean | undefined>({
  key: 'has-allowed-microphone',
  set: ({ set }, hasAllowedMicrophone) =>
    set(hasAllowedMicrophoneState, hasAllowedMicrophone),
  get: ({ get }) => get(hasAllowedMicrophoneState),
});

export const hasWebRTCSupportSelector = selector<boolean | undefined>({
  key: 'has-webrtc-support',
  set: ({ set }, hasWebRTCSupport) =>
    set(hasWebRTCSupportState, hasWebRTCSupport),
  get: ({ get }) => get(hasWebRTCSupportState),
});

export const hasConnectedCameraSelector = selector<boolean | undefined>({
  key: 'has-connected-camera',
  set: ({ set }, hasConnectedCamera) =>
    set(hasConnectedCameraState, hasConnectedCamera),
  get: ({ get }) => get(hasConnectedCameraState),
});

export const hasConnectedMicrophoneSelector = selector<boolean | undefined>({
  key: 'has-connected-microphone',
  set: ({ set }, hasConnectedMicrophone) =>
    set(hasConnectedMicrophoneState, hasConnectedMicrophone),
  get: ({ get }) => get(hasConnectedMicrophoneState),
});

export const hasFinishedSettingPermissionsSelector = selector<boolean>({
  key: 'has-finished-setting-permissions',
  set: ({ set }, hasFinishedSettingPermissions) =>
    set(hasFinishedSettingPermissionsState, hasFinishedSettingPermissions),
  get: ({ get }) => get(hasFinishedSettingPermissionsState),
});
