import Peer from 'peerjs';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { hurvClient } from '../api';

interface PeerState {
  peerServerConnection: Peer | null;
  peerCalls: {
    [key: string]: {
      camera: Peer.MediaConnection;
      heroScreenShare?: Peer.MediaConnection;
      villagerScreenShare?: Peer.MediaConnection;
    };
  };
  addPeerCall: (
    newCall: Peer.MediaConnection,
    isHeroScreenShare?: boolean
  ) => void;
  removePeerCall: (callIdToRemove: string) => void;
  removeScreenSharePeerCall: (villagerIdToRemove: string) => void;
  closePeerServerConnection: () => void;
}

const defaultState: PeerState = {
  peerServerConnection: null,
  peerCalls: {},
  addPeerCall: () => {},
  removePeerCall: () => {},
  removeScreenSharePeerCall: () => {},
  closePeerServerConnection: () => {},
};

export const socketIdToPeerId = (socketId: string) => {
  return socketId.replace(/_/g, 'UNDERSCORE').replace(/-/g, 'DASH');
};
export const peerIdToSocketId = (peerId: string) => {
  return peerId.replace(/UNDERSCORE/g, '_').replace(/DASH/g, '-');
};

export const PeerContext = createContext<PeerState>(defaultState);

export const usePeerContext = () => useContext(PeerContext);

export const PeerContextProvider = ({ children }: { children: ReactNode }) => {
  const [peerServerConnection, setPeerServerConnection] = useState<
    PeerState['peerServerConnection']
  >(defaultState.peerServerConnection);
  const [peerCalls, setPeerCalls] = useState<PeerState['peerCalls']>(
    defaultState.peerCalls
  );

  const addPeerCall = (
    newPeerCall: Peer.MediaConnection,
    isHeroScreenShare = false
  ) => {
    const { isScreenShare = false } = (newPeerCall.metadata ?? {
      isScreenShare: false,
    }) as {
      isScreenShare?: boolean;
    };

    setPeerCalls((prev) => ({
      ...prev,
      [newPeerCall.peer]: {
        camera: !isScreenShare ? newPeerCall : prev[newPeerCall.peer]?.camera,
        heroScreenShare:
          isScreenShare && isHeroScreenShare
            ? newPeerCall
            : prev[newPeerCall.peer]?.heroScreenShare,
        villagerScreenShare:
          isScreenShare && !isHeroScreenShare
            ? newPeerCall
            : prev[newPeerCall.peer]?.villagerScreenShare,
      },
    }));
  };

  const removePeerCall = (callIdToRemove: string) => {
    setPeerCalls((prev) => {
      const peerId = socketIdToPeerId(callIdToRemove);
      if (prev[peerId]) {
        prev[peerId].camera?.close(); // WARNING: This may cause wrc connection leaks
        prev[peerId].heroScreenShare?.close();
        prev[peerId].villagerScreenShare?.close();
      } else {
        console.error('POSSIBLE WEBRTC LEAK!');
      }
      const { [peerId]: removed, ...rest } = prev;
      return rest;
    });
  };

  const removeScreenSharePeerCall = (villagerId: string) => {
    const peerId = socketIdToPeerId(villagerId);
    setPeerCalls((prev) => {
      if (prev[peerId]) {
        prev[peerId].villagerScreenShare?.close();
      } else {
        console.error('Screen share: POSSIBLE WEBRTC LEAK!');
      }

      return {
        ...prev,
        [peerId]: {
          camera: prev[peerId]?.camera,
          heroScreenShare: prev[peerId]?.heroScreenShare,
        },
      };
    });
  };

  const closePeerServerConnection = () => {
    console.log('Destroy peer server connection');
    peerServerConnection?.destroy();
    setPeerServerConnection(defaultState.peerServerConnection);
    setPeerCalls(defaultState.peerCalls);
  };

  const openPeerServerConnection = () => {
    console.log(
      'Attemting to connect to peer server with id:',
      socketIdToPeerId(hurvClient.socket.id)
    );
    const peerConnection = new Peer(
      socketIdToPeerId(hurvClient.socket.id),
      process.env.NODE_ENV === 'development'
        ? {
            host: `/`,
            port: 3001,
            debug: 2,
          }
        : {
            host: 'townie-peer-server.herokuapp.com',
            port: 443,
            secure: true,
          }
    );
    if (peerConnection.disconnected) {
      console.error('Failed to connect to peer server', peerConnection);
    } else {
      console.log('Connected to peer server with peer id:', peerConnection);
      hurvClient.connectedToPeerServer();
    }
    setPeerServerConnection(peerConnection);
  };

  useEffect(() => {
    hurvClient.socket.on('JOINED_ROOM', openPeerServerConnection);

    return () => {
      hurvClient.socket.off('JOINED_ROOM', openPeerServerConnection);
    };
  }, []);

  return (
    <PeerContext.Provider
      value={{
        peerServerConnection,
        peerCalls,
        addPeerCall,
        removePeerCall,
        removeScreenSharePeerCall,
        closePeerServerConnection,
      }}
    >
      {children}
    </PeerContext.Provider>
  );
};

export default PeerContextProvider;
