import { Box, Flex, Text } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { chatMessageFamilySelector } from '../../state/chat';

export const ChatMessage = ({ messageId }: { messageId: string }) => {
  const [message] = useRecoilState(chatMessageFamilySelector(messageId)) as [
    ChatMessage,
    () => void
  ];

  const getTime = (time: Date) => {
    return new Date(time).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <Box margin="0 12px 12px 12px">
      <Flex>
        <Box
          minWidth="38px"
          maxWidth="38px"
          height="38px"
          borderRadius="22px"
          marginRight="12px"
          style={{ imageRendering: 'pixelated' }}
          overflow="hidden"
          backgroundColor="gray.700"
          border="1px solid #1B3A57"
        >
          <Box
            marginLeft="-692px"
            marginTop="-24px"
            width="921px"
            height="77px"
            backgroundImage={`url(/assets/characters/${message.villagerSprite}_idle_anim_32x32.png)`}
            backgroundSize="100%"
          />
        </Box>
        <Flex direction="column">
          <Flex spacing="6px">
            <Text fontSize="16px" color="#1B3A57">
              {message.fromVillagerName}
            </Text>
            <Text color="#545454" fontSize="10px" margin="auto 0 2px 6px">
              {getTime(message.timeStamp)}
            </Text>
          </Flex>
          <Text marginTop="-6px" fontSize="14px" color="#1D1D1D">
            {message.message}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ChatMessage;
