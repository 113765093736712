import { Box, Flex } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThumbsUp,
  faThumbsDown,
  faHandSparkles,
  faLaughBeam,
  faSurprise,
} from '@fortawesome/free-solid-svg-icons';

const emotes = [
  {
    key: 'wave',
    id: faHandSparkles,
  },
  {
    key: 'smile',
    id: faLaughBeam,
  },
  {
    key: 'surprised',
    id: faSurprise,
  },
  {
    key: 'like',
    id: faThumbsUp,
  },
  {
    key: 'dislike',
    id: faThumbsDown,
  },
];
export const Emotes = (props: any) => {
  const handleClick = (emote: any) => {
    props.onEmote(emote);
  };

  return (
    <>
      <Box
        width="240px"
        height="55px"
        marginTop="auto"
        padding="4px 12px"
        borderRadius="36px"
        backgroundColor="#3F3F3F"
      >
        <Flex>
          {emotes.map((emote) => (
            <Box
              key={emote.key}
              onClick={() => handleClick(emote.key)}
              margin="0 auto"
              fontSize="30px"
              cursor="pointer"
            >
              <FontAwesomeIcon icon={emote.id} color="#F0F0F0" />
            </Box>
          ))}
        </Flex>
      </Box>
    </>
  );
};

export default Emotes;
