import { atom } from 'recoil';

export const hasAllowedCameraState = atom<boolean | undefined>({
  key: 'has-allowed-camera-atom',
  default: undefined,
});

export const hasAllowedMicrophoneState = atom<boolean | undefined>({
  key: 'has-allowed-microphone-atom',
  default: undefined,
});

export const hasWebRTCSupportState = atom<boolean | undefined>({
  key: 'has-webrtc-support-atom',
  default: undefined,
});

export const hasConnectedCameraState = atom<boolean | undefined>({
  key: 'has-connected-camera-atom',
  default: undefined,
});

export const hasConnectedMicrophoneState = atom<boolean | undefined>({
  key: 'has-connected-microphone-atom',
  default: undefined,
});

export const hasFinishedSettingPermissionsState = atom<boolean>({
  key: 'has-finished-setting-permissions-atom',
  default: false,
});
