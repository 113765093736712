import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { hurvClient } from '../api';
import {
  villagerListSelector,
  addVillagerSelector,
  removeVillagerSelector,
  moveVillagerSelector,
  villagerEmoteSelector,
  toggleVillagerCameraSelector,
  toggleVillagerMicrophoneSelector,
  villagerStatusSelector,
} from '../state/villagers';

const useVillagers = () => {
  const setVillagers = useSetRecoilState(villagerListSelector);
  const addVillager = useSetRecoilState(addVillagerSelector);
  const removeVillager = useSetRecoilState(removeVillagerSelector);
  const moveVillager = useSetRecoilState(moveVillagerSelector);
  const villagerEmote = useSetRecoilState(villagerEmoteSelector);
  const setVillagerStatus = useSetRecoilState(villagerStatusSelector);

  const handleToggleVillagerCamera = useSetRecoilState(
    toggleVillagerCameraSelector
  );
  const handleToggleVillagerMicrophone = useSetRecoilState(
    toggleVillagerMicrophoneSelector
  );

  const handleSetVillagerStatus = (payload: {
    villagerId: Villager['id'];
    status: string;
  }) => setVillagerStatus(payload);

  const handleSetVillagers = ({ villagers }: { villagers: Villager[] }) =>
    setVillagers(villagers);

  const handleAddVillagers = ({ villager }: { villager: Villager }) =>
    addVillager(villager);

  const handleRemoveVillagers = ({ villagerId }: { villagerId: string }) =>
    removeVillager(villagerId);

  const handleVillagerEmote = (payload: {
    villagerId: Villager['id'];
    emoteKey: string;
  }) => villagerEmote(payload);

  const handleMoveVillager = (payload: {
    villagerId: string;
    position: Villager['data']['position'];
  }) => moveVillager(payload);

  useEffect(() => {
    hurvClient.socket.on('INITIAL_USER_LIST', handleSetVillagers);
    hurvClient.socket.on('VILLAGER_CONNECTED', handleAddVillagers);
    hurvClient.socket.on('VILLAGER_DISCONNECTED', handleRemoveVillagers);
    hurvClient.socket.on('VILLAGER_EMOTE', handleVillagerEmote);
    hurvClient.socket.on('VILLAGER_MOVED', handleMoveVillager);
    hurvClient.socket.on('VILLAGER_TOGGLED_CAMERA', handleToggleVillagerCamera);
    hurvClient.socket.on(
      'VILLAGER_TOGGLED_MICROPHONE',
      handleToggleVillagerMicrophone
    );
    hurvClient.socket.on('VILLAGER_CHANGED_STATUS', handleSetVillagerStatus);
    return () => {
      hurvClient.socket.off('INITIAL_USER_LIST', () => setVillagers);
      hurvClient.socket.off('VILLAGER_CONNECTED', () => addVillager);
      hurvClient.socket.off('VILLAGER_DISCONNECTED', () => removeVillager);
      hurvClient.socket.off('VILLAGER_EMOTE', () => handleVillagerEmote);
      hurvClient.socket.off('VILLAGER_MOVED', () => handleMoveVillager);
      hurvClient.socket.off(
        'VILLAGER_TOGGLED_CAMERA',
        handleToggleVillagerCamera
      );
      hurvClient.socket.off(
        'VILLAGER_TOGGLED_MICROPHONE',
        handleToggleVillagerMicrophone
      );
      hurvClient.socket.off('VILLAGER_CHANGED_STATUS', handleSetVillagerStatus);
    };
  }, [
    setVillagers,
    addVillager,
    removeVillager,
    moveVillager,
    villagerEmote,
    setVillagerStatus,
    handleToggleVillagerCamera,
    handleToggleVillagerMicrophone,
  ]);

  return null;
};

export default useVillagers;
