import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { MediaConnection } from 'peerjs';
import { Box, BoxProps } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { MediaFrame } from '../media/MediaFrame';
import Camera from '../Camera';
import { selfIsCameraEnabledSelector } from '../../state/calls/selectors';

const Container = styled(Box)`
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
`;

const Stream = styled.div`
  position: relative;
  vertical-align: middle;
  align-self: center;
  overflow: hidden;
  display: inline-block;
  animation: show 0.4s ease;

  @keyframes show {
    0% {
      opacity: 0;
      transform: scale(0.4) translateY(20px);
    }
    100% {
      opacity: 1;
      transform: scale(1) translateY(0);
    }
  }
`;

const area = (
  Increment: number,
  Count: number,
  Width: number,
  Height: number,
  Margin = 10
) => {
  let i = 0;
  let w = 0;
  let h = Increment * 0.75 + Margin * 2;
  while (i < Count) {
    if (w + Increment > Width) {
      w = 0;
      h = h + Increment * 0.75 + Margin * 2;
    }
    w = w + Increment + Margin * 2;
    i += 1;
  }
  if (h > Height) return false;
  return Increment;
};

interface ResizableGridProps extends BoxProps {
  mediaFrames: { key: string; callId: string; peerCall: MediaConnection }[];
}

const ResizeableGrid = ({ mediaFrames, ...rest }: ResizableGridProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isCameraEnabled = useRecoilValue(selfIsCameraEnabledSelector);
  const [width, setWidth] = useState<number>(100);
  const [margin, setMargin] = useState<number>(100);

  const Dish = () => {
    const Scenary = containerRef.current;
    if (!Scenary) return;

    const Margin = 2;
    const Width = Scenary.offsetWidth - Margin * 2;
    const Height = Scenary.offsetHeight - Margin * 2;
    let max = 0;

    // loop (i recommend you optimize this)
    let i = 1;
    while (i < 5000) {
      const w = area(
        i,
        mediaFrames.length + (isCameraEnabled ? 1 : 0),
        Width,
        Height,
        Margin
      );
      if (w === false) {
        max = i - 1;
        break;
      }
      i += 1;
    }

    max -= Margin * 2;
    setWidth(max);
    setMargin(Margin);
  };

  useEffect(() => {
    Dish();
  }, [mediaFrames, mediaFrames.length]);

  useEffect(() => {
    if (containerRef.current) {
      window.onresize = Dish;
    }

    return () => {
      window.onresize = null;
    };
  }, [containerRef.current]);

  return (
    <Container ref={containerRef} {...rest}>
      {mediaFrames.map((mediaFrame) => (
        <Stream
          key={mediaFrame.key}
          style={{
            width,
            height: width * 0.75,
            margin,
          }}
        >
          <MediaFrame
            callId={mediaFrame.callId}
            peerCall={mediaFrame.peerCall}
            width="100%"
            height="100%"
          />
        </Stream>
      ))}
      {isCameraEnabled && (
        <Stream
          key="camera"
          style={{
            width,
            height: width * 0.75,
            margin,
          }}
        >
          <Camera hideUi width="100%" height="100%" />
        </Stream>
      )}
    </Container>
  );
};

export default ResizeableGrid;
