import { io, Socket } from 'socket.io-client';

export default class HurvClient {
  socket: Socket;

  constructor() {
    this.socket = io(`${process.env.REACT_APP_WEBSOCKET_URL}`, {
      withCredentials: true,
      transports: ['websocket'],
      autoConnect: false,
    });

    this.socket.on('connect', () => {
      console.log(`Websocket connection established.`);
    });

    this.socket.on('disconnect', () => {
      console.log('Websocket connection closed.');
    });

    this.socket.on('connect_error', (err) => {
      console.log(
        'Unable to establish websocket connection for room: ',
        err.message
      );
    });
  }

  connect() {
    console.log('Attempting to open websocket connection');
    this.socket.connect();
  }

  disconnect() {
    this.socket.disconnect();
  }

  userEmote(emoteKey: string) {
    this.socket.emit('emote', emoteKey);
  }

  chat(chatMessage: UnhandledChatMessage) {
    this.socket.emit('chat', chatMessage);
  }

  move = (position: Villager['data']['position']) => {
    this.socket.emit('move', position);
  };

  toggleCamera = (isEnabled: boolean) => {
    this.socket.emit('TOGGLE_CAMERA', isEnabled);
  };

  toggleMicrophone = (isEnabled: boolean) => {
    this.socket.emit('TOGGLE_MICROPHONE', isEnabled);
  };

  setStatus = (status: string) => {
    this.socket.emit('CHANGE_STATUS', status);
  };

  stopScreenSharing = () => {
    this.socket.emit('STOP_SCREEN_SHARING');
  };

  notifyVillager = (villagerId: string) => {
    this.socket.emit('NOTIFY_VILLAGER', villagerId);
  };

  ping = () => {
    this.socket.emit('PING');
  };

  joinRoom = ({
    roomId,
    user,
    authToken,
  }: {
    roomId: string;
    user: Villager;
    authToken: string;
  }) => {
    this.socket.emit('JOIN_ROOM', { roomId, user, authToken });
  };

  connectedToPeerServer = () => {
    this.socket.emit('CONNECTED_TO_PEER_SERVER');
  };
}
