import Peer from 'peerjs';
import { useEffect } from 'react';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { hurvClient } from '../api';
import {
  addCallSelector,
  removeCallSelector,
  selfScreenStreamSelector,
  selfStreamSelector,
} from '../state/calls';
import {
  peerIdToSocketId,
  socketIdToPeerId,
  usePeerContext,
} from '../context/PeerContext';
import useScreenShare from './useScreenShare';

const CAPTURE_OPTIONS = {
  audio: true,
  video: {
    facingMode: 'environment',
    width: 640,
    height: 480,
    frameRate: { ideal: 12, max: 15 },
  },
};

const useCall = () => {
  const addCall = useSetRecoilState(addCallSelector);
  const removeCall = useSetRecoilState(removeCallSelector);
  const [selfMediaStream, setSelfMediaStream] =
    useRecoilState(selfStreamSelector);

  const selfScreenStream = useRecoilValue(selfScreenStreamSelector);

  const { peerServerConnection, peerCalls, addPeerCall, removePeerCall } =
    usePeerContext();
  const { createScreenShareCall, removeScreenShareCall } = useScreenShare();

  const callRemoteVillager = ({ call }: { call: Call }) => {
    if (!selfMediaStream || peerServerConnection === null) return;
    const peerCall = peerServerConnection.call(
      socketIdToPeerId(call.id),
      selfMediaStream
    );
    if (!peerCall) return;
    addPeerCall(peerCall);
    addCall(call);

    if (selfScreenStream) {
      createScreenShareCall(call.id, selfScreenStream);
    }
  };

  const hangUpCallWithRemoteVillager = ({ callId }: { callId: string }) => {
    removeCall(callId);
    removePeerCall(callId);
  };

  useEffect(() => {
    hurvClient.socket.on(
      'VILLAGER_STOPPED_SCREEN_SHARING',
      removeScreenShareCall
    );
    return () => {
      hurvClient.socket.off(
        'VILLAGER_STOPPED_SCREEN_SHARING',
        removeScreenShareCall
      );
    };
  }, [hurvClient, selfMediaStream, selfScreenStream, peerServerConnection]);

  useEffect(() => {
    hurvClient.socket.on('CREATE_CALL', callRemoteVillager);
    return () => {
      hurvClient.socket.off('CREATE_CALL', callRemoteVillager);
    };
  }, [hurvClient, selfMediaStream, selfScreenStream, peerServerConnection]);

  useEffect(() => {
    hurvClient.socket.on('DESTROY_CALL', hangUpCallWithRemoteVillager);
    return () => {
      hurvClient.socket.off('DESTROY_CALL', hangUpCallWithRemoteVillager);
    };
  }, [hurvClient, peerServerConnection, peerCalls]);

  const answerCallFromRemoteVillager = (peerCall: Peer.MediaConnection) => {
    if (peerServerConnection === null) return;

    addPeerCall(peerCall);

    addCall({
      id: peerIdToSocketId(peerCall.peer),
    });

    if (peerCall.metadata?.isScreenShare) {
      peerCall.answer(undefined); // don't answer with video on screen share calls
    } else {
      peerCall.answer(selfMediaStream || undefined);
      if (selfScreenStream) {
        createScreenShareCall(peerCall.peer, selfScreenStream);
      }
    }
  };

  useEffect(() => {
    if (!peerServerConnection || !selfMediaStream) {
      return;
    }
    peerServerConnection.on('call', answerCallFromRemoteVillager);
    // eslint-disable-next-line consistent-return
    return () => {
      peerServerConnection.off('call', answerCallFromRemoteVillager);
    };
  }, [peerServerConnection, selfMediaStream, selfScreenStream]);

  useEffect(() => {
    if (!selfMediaStream) {
      navigator.mediaDevices.getUserMedia(CAPTURE_OPTIONS).then((newStream) => {
        setSelfMediaStream(newStream);
      });
    }
  }, []);

  return null;
};

export default useCall;
