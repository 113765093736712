import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { mapPanSelector } from '../state/room';
import office from './maps/office.json';
import Map from './Map';
import { heroPositionSelector } from '../state/hero';

const GRID_CELL_PX_SIZE: number = office.gridCell;

export const Frame = ({ ...rest }) => {
  const mapPan = useRecoilValue(mapPanSelector);
  const heroPosition = useRecoilValue(heroPositionSelector);
  const [pixelSize, _setPixelsize] = useState<number>(2.5);
  const pixelSizeRef = React.useRef(pixelSize);
  const setPixelsize = (data: number) => {
    pixelSizeRef.current = data;
    _setPixelsize(data);
  };

  const handleScroll = (e: { deltaY: number }) => {
    const current = pixelSizeRef.current + e.deltaY / 100;
    if (current <= 1) {
      setPixelsize(1);
    } else if (current >= 10) {
      setPixelsize(10);
    } else {
      setPixelsize(pixelSizeRef.current + e.deltaY / 100);
    }
  };

  useEffect(() => {
    // document.addEventListener('wheel', handleScroll);
    return () => {
      // document.removeEventListener('wheel', handleScroll);
    };
  }, []);

  const Move = () => {
    if (mapPan) {
      const gridCell = GRID_CELL_PX_SIZE * pixelSize;
      const difX = heroPosition.coordinates.x - mapPan.x;
      const difY = heroPosition.coordinates.y - mapPan.y;
      return `translate3d(${difX * gridCell}px, ${difY * gridCell}px, 0)`;
    }
    return `translate3d(0, 0, 0)`;
  };

  return (
    <Box
      marginLeft={`${-pixelSize * GRID_CELL_PX_SIZE}px`}
      transition="linear 0.2s"
      width={0}
      height={0}
      position="relative"
      zIndex="0"
      animation="fadein 0.3s"
      opacity="0.9"
      transform={Move()}
      {...rest}
    >
      <Map pixelSize={pixelSize} griddCellPxSize={GRID_CELL_PX_SIZE} />
    </Box>
  );
};

export default Frame;
