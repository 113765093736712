import { atom, atomFamily } from 'recoil';

export const chatMessageListState = atom<ChatMessage['fromVillagerName'][]>({
  key: 'chat-message-list-atom',
  default: [],
});

export const chatFamilyState = atomFamily<
  { [key: string]: ChatMessage },
  string
>({
  key: 'chat-message-list-state',
  default: {} as { [key: string]: ChatMessage },
});

export const unreadState = atom<number>({
  key: 'unread-atom',
  default: 0,
});

export const chatIsOpenState = atom<boolean>({
  key: 'chat-is-open-atom',
  default: false,
});
