import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import firebase from 'firebase';
import '../api/firebase/index';

interface AuthState {
  loggedInUser: LoggedInUser | undefined;
  isLoadingAuthState: boolean;
  idToken: string | undefined;
  loginWithGoogle: () => void;
  signOutFromGoogle: () => void;
}

interface IdTokenResult extends firebase.auth.IdTokenResult {
  token: string;
  claims: {
    name: string;
    picture: string;
    email: string;
  };
}

const defaultState: AuthState = {
  loggedInUser: undefined,
  idToken: undefined,
  isLoadingAuthState: true,
  loginWithGoogle: () => {},
  signOutFromGoogle: () => {},
};

export const AuthContext = createContext<AuthState>(defaultState);

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [loggedInUser, setLoggedInUser] = useState<AuthState['loggedInUser']>(
    defaultState.loggedInUser
  );
  const [idToken, setIdToken] = useState<AuthState['idToken']>(
    defaultState.idToken
  );
  const [isLoadingAuthState, setIsLoadingAuthState] = useState<
    AuthState['isLoadingAuthState']
  >(defaultState.isLoadingAuthState);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (userCred) => {
      if (userCred) {
        const result = (await userCred.getIdTokenResult()) as IdTokenResult;
        if (
          result &&
          result.token &&
          result.claims?.name &&
          result.claims?.picture &&
          result.claims?.email
        ) {
          setLoggedInUser({
            name: result.claims.name,
            imageUrl: result.claims.picture,
            email: result.claims?.email,
          });
          setIdToken(result.token);
        }
      }
      setIsLoadingAuthState(false);
    });
  }, []);

  const loginWithGoogle = async () => {
    await firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider());
  };

  const signOutFromGoogle = async () => {
    await firebase
      .auth()
      .signOut()
      .then(async () => {
        setLoggedInUser(undefined);
        setIdToken(undefined);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        loggedInUser,
        idToken,
        isLoadingAuthState,
        loginWithGoogle,
        signOutFromGoogle,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
