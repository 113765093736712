import { Box, Flex, FlexProps, Text, Center, HStack } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile, faDesktop } from '@fortawesome/free-solid-svg-icons';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Emotes } from './Emotes';
import {
  heroNameSelector,
  heroSpriteSelector,
  heroEmoteSelector,
  heroStatusSelector,
} from '../state/hero';
import { hurvClient } from '../api';
import { selfScreenStreamSelector } from '../state/calls';
import useScreenShare from '../hooks/useScreenShare';
import { isFocusedSelector } from '../state/room';

const User = (props: FlexProps) => {
  const name = useRecoilValue(heroNameSelector);
  const heroStatus = useRecoilValue<string>(heroStatusSelector);
  const isFocused = useRecoilValue(isFocusedSelector);
  const sprite = useRecoilValue(heroSpriteSelector);
  const setEmoteKey = useSetRecoilState(heroEmoteSelector);
  const [imageUrl, setImageUrl] = useState<string>();
  const [emotePanelVisible, setEmotePanelVisible] = useState<boolean>(false);
  const selfScreenStream = useRecoilValue(selfScreenStreamSelector);
  const { initializeScreenSharing, stopScreenSharing } = useScreenShare();

  useEffect(() => {
    setImageUrl(`url(/assets/characters/${sprite}_idle_anim_32x32.png)`);
  }, [sprite]);

  const handleEmoteMenu = () => {
    if (emotePanelVisible) {
      setEmotePanelVisible(false);
    } else {
      setEmotePanelVisible(true);
    }
  };

  const getStatusData = () => {
    if (!isFocused) {
      return {
        key: 'Away from tab',
        color: '#FFAA2C',
      };
    }
    if (heroStatus === 'online') {
      return {
        key: 'Online',
        color: '#2CC0FF',
      };
    }
    if (heroStatus === 'bussy') {
      return {
        key: 'Away from tab',
        color: '#FFAA2C',
      };
    }

    return {
      key: 'Offline',
      color: '#C0C0C0',
    };
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const clickedEmote = (emote: any) => {
    hurvClient.userEmote(emote);
    setEmoteKey(emote);
    setEmotePanelVisible(false);
  };

  const handleScreenShare = useCallback(() => {
    if (!selfScreenStream) {
      initializeScreenSharing();
    } else {
      stopScreenSharing();
    }
  }, [selfScreenStream, initializeScreenSharing, stopScreenSharing]);

  return (
    <Flex direction="column" margin="0 12px" {...props}>
      {emotePanelVisible && <Emotes onEmote={clickedEmote} />}
      <Box
        height="55px"
        marginTop="auto"
        borderRadius="36px"
        backgroundColor="#3F3F3F"
      >
        <Flex>
          <Box
            width="45px"
            height="45px"
            border={`3px solid ${getStatusData().color}`}
            borderRadius="22px"
            backgroundColor="#3F3F3F"
            style={{ imageRendering: 'pixelated' }}
            margin="5px"
            overflow="hidden"
          >
            <Box
              marginLeft="-690px"
              marginTop="-24px"
              width="921px"
              height="77px"
              backgroundImage={imageUrl}
              backgroundSize="100%"
            />
          </Box>
          <Center mx="12px">
            <Box minWidth="82px" maxWidth="100px">
              <Text
                color="white"
                fontSize="14px"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
              >
                {capitalizeFirstLetter(name)}
              </Text>
              <Text
                color={getStatusData().color}
                fontSize="12px"
                marginTop="-6px"
                whiteSpace="nowrap"
              >
                {getStatusData().key}
              </Text>
            </Box>
          </Center>
          <Center mr="12px">
            <HStack spacing="12px">
              <Box onClick={handleEmoteMenu} fontSize="25px" cursor="pointer">
                <FontAwesomeIcon icon={faSmile} color="#F0F0F0" />
              </Box>
              <Box onClick={handleScreenShare} fontSize="25px" cursor="pointer">
                <FontAwesomeIcon
                  icon={faDesktop}
                  color={selfScreenStream ? '#42E583' : '#F0F0F0'}
                />
              </Box>
            </HStack>
          </Center>
        </Flex>
      </Box>
    </Flex>
  );
};

export default User;
