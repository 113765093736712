import { Box } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import { useRecoilState } from 'recoil';
import { selfIsCameraEnabledSelector } from '../../state/calls/selectors';

export const CameraButton = () => {
  const [isCameraEnabled, toggleCamera] = useRecoilState(
    selfIsCameraEnabledSelector
  );

  return (
    <Box
      cursor="pointer"
      width="60px"
      height="60px"
      backgroundColor="#FFF"
      borderRadius="30px"
      fontSize="26px"
      marginRight="12px"
      onClick={() => toggleCamera((prev) => !prev)}
    >
      {isCameraEnabled ? (
        <Box marginLeft="16px" marginTop="9px">
          <FontAwesomeIcon color="#000" icon={faVideo} />
        </Box>
      ) : (
        <Box marginLeft="14px" marginTop="9px">
          <FontAwesomeIcon color="#EA3535" icon={faVideoSlash} />
        </Box>
      )}
    </Box>
  );
};

export default CameraButton;
