import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { hurvClient } from '../api';
import { addChatMessageSelector } from '../state/chat';

const useChat = () => {
  const addMessage = useSetRecoilState(addChatMessageSelector);

  const handleChatMessageRecieved = ({
    chatMessage,
  }: {
    chatMessage: ChatMessage;
  }) => {
    addMessage(chatMessage);
  };

  useEffect(() => {
    hurvClient.socket.on('CHAT_MESSAGE', handleChatMessageRecieved);
    return () => {
      hurvClient.socket.off('CHAT_MESSAGE', handleChatMessageRecieved);
    };
  }, [hurvClient]);

  return null;
};

export default useChat;
