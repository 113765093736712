import {
  Box,
  HStack,
  IconButton,
  VStack,
  BoxProps,
  Center,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faVideo,
  faVideoSlash,
  faMicrophone,
  faMicrophoneSlash,
} from '@fortawesome/free-solid-svg-icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  selfIsCameraEnabledSelector,
  selfIsMicrophoneEnabledSelector,
  selfStreamSelector,
} from '../state/calls';

interface CameraProps extends BoxProps {
  hideUi?: boolean;
}

const Camera = ({ hideUi, ...rest }: CameraProps) => {
  const [isCameraEnabled, toggleCamera] = useRecoilState(
    selfIsCameraEnabledSelector
  );
  const [isMicrophoneEnabled, toggleMicrophone] = useRecoilState(
    selfIsMicrophoneEnabledSelector
  );
  const selfWebcamStream = useRecoilValue(selfStreamSelector);

  const videoRef = useRef<HTMLVideoElement | null>(null);

  if (selfWebcamStream && videoRef.current && !videoRef.current.srcObject) {
    videoRef.current.srcObject = selfWebcamStream;
  }

  function handleCanPlay() {
    videoRef.current?.play();
  }

  return (
    <Box
      id="video"
      borderRadius="12px"
      backgroundColor="#3F3F3F"
      overflow="hidden"
      {...rest}
    >
      {!hideUi && (
        <VStack
          zIndex="1"
          borderRadius="12px"
          position="fixed"
          overflow="hidden"
          justifyContent="flex-end"
          alignItems="flex-start"
          {...rest}
        >
          <HStack
            spacing="0"
            width="100%"
            display="flex"
            justifyContent="flex-start"
            color="white"
            bg="linear-gradient(0deg, #000000, transparent 85%)"
            padding="0px 5px"
          >
            <IconButton
              onClick={() => toggleCamera((prev) => !prev)}
              aria-label="Disable Camera"
              color={isCameraEnabled ? '#FFF' : '#EA3535'}
              variant="ghost"
              _hover={{ bg: 'transparent' }}
              icon={
                <FontAwesomeIcon
                  icon={isCameraEnabled ? faVideo : faVideoSlash}
                />
              }
            />
            <IconButton
              onClick={() => toggleMicrophone((prev) => !prev)}
              aria-label="Disable Camera"
              color={isMicrophoneEnabled ? '#FFF' : '#EA3535'}
              variant="ghost"
              _hover={{ bg: 'transparent' }}
              icon={
                <FontAwesomeIcon
                  icon={isMicrophoneEnabled ? faMicrophone : faMicrophoneSlash}
                />
              }
            />
          </HStack>
        </VStack>
      )}
      <Center width="100%" height="100%">
        <video
          style={{ transform: 'rotateY(180deg)' }}
          ref={videoRef}
          onCanPlay={handleCanPlay}
          autoPlay
          playsInline
          muted
        />
      </Center>
    </Box>
  );
};

export default Camera;
