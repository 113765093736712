import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import { ChakraProvider } from '@chakra-ui/react';
import { RecoilRoot } from 'recoil';
import { HomePage } from './pages/HomePage';
import { Plans } from './pages/Plans';
import { Order } from './pages/Order';
import { CreateFreeRoom } from './pages/CreateFreeRoom';
import { ExpiredRoom } from './pages/ExpiredRoom';
import { Room } from './pages/Room';
import theme from './theme';
import './styles.css';
import { PeerContextProvider } from './context/PeerContext';
import { AuthContextProvider } from './context/AuthContextProvider';
import { SelectHero } from './pages/Lobby';

function App() {
  return (
    <Router>
      <ChakraProvider resetCSS theme={theme}>
        <RecoilRoot>
          <AuthContextProvider>
            <Switch>
              <Route path="/plans">
                <Plans />
              </Route>
              <Route path="/order">
                <Order />
              </Route>
              <Route path="/space-expired">
                <ExpiredRoom />
              </Route>
              <Route path="/new-room">
                <CreateFreeRoom />
              </Route>
              <Route path="/lobby/:roomId">
                <SelectHero />
              </Route>
              <Route path="/:roomId">
                <PeerContextProvider>
                  <Room />
                </PeerContextProvider>
              </Route>
              <Route path="/">
                <HomePage />
              </Route>
            </Switch>
          </AuthContextProvider>
        </RecoilRoot>
      </ChakraProvider>
    </Router>
  );
}

export default App;
