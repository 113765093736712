import { atom } from 'recoil';
// import outside from '../../game/maps/outside.json';
import office from '../../game/maps/office.json';

export const roomIdState = atom<Room['roomId']>({
  key: 'room-id-atom',
  default: undefined,
});

export const roomIsLoadingState = atom<boolean>({
  key: 'room-is-loading-atom',
  default: true,
});

export const mapState = atom<Room['map']>({
  key: 'map-atom',
  default: office,
});

export const maximisedState = atom<Room['isMaximized']>({
  key: 'is-maximized-atom',
  default: false,
});

export const mapPanState = atom<Room['mapPan']>({
  key: 'map-pan-atom',
  default: undefined,
});

export const joystickDirectionState = atom<string | null>({
  key: 'joystick-direction-atom',
  default: null,
});

export const isFocusedState = atom<boolean>({
  key: 'is-focused-atom',
  default: true,
});
