import { atomFamily, atom } from 'recoil';
import Peer from 'peerjs';

interface OpenCall extends Call {
  peerCall?: Peer.MediaConnection;
}

export const callListState = atom<OpenCall['id'][]>({
  key: 'call-list-atom',
  default: [],
});

export const callFamilyState = atomFamily({
  key: 'call-list-state',
  default: {} as { [key: string]: OpenCall },
});

export const selfStreamState = atom<MediaStream | null>({
  key: 'self-stream-atom',
  default: null,
});

export const selfScreenStreamState = atom<MediaStream | null>({
  key: 'self-screen-stream-atom',
  default: null,
});

export const selfIsCameraEnabledState = atom<
  Villager['media']['isCameraEnabled']
>({
  key: 'self-is-camera-enabled-atom',
  default: true,
});

export const selfIsMicrophoneEnabledState = atom<
  Villager['media']['isMicrophoneEnabled']
>({
  key: 'self-is-microphone-enabled-atom',
  default: true,
});

export const maximizedCallIdState = atom<string | undefined>({
  key: 'maximized-call-id-atom',
  default: undefined,
});
