import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Button,
  Text,
  Box,
  Kbd,
  Icon,
  Center,
  HStack,
  BoxProps,
} from '@chakra-ui/react';
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import { useEffect, useState } from 'react';
import {
  faDesktop,
  faVideo,
  faMicrophone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DotProps extends BoxProps {
  isActive: boolean;
}

const Dot = ({ isActive }: DotProps) => {
  return (
    <Box
      width={isActive ? '28px' : '14px'}
      transition="linear 0.2s"
      height="14px"
      borderRadius="25px"
      backgroundColor={isActive ? '#1B3A57' : '#C4C4C4'}
    />
  );
};

interface ProgressDotsProps extends BoxProps {
  step: number;
}

const ProgressDots = ({ step, ...rest }: ProgressDotsProps) => {
  return (
    <Center {...rest}>
      <HStack spacing="6px" justify="space-between">
        <Dot isActive={step === 0} />
        <Dot isActive={step === 1} />
        <Dot isActive={step === 2} />
        <Dot isActive={step === 3} />
      </HStack>
    </Center>
  );
};

export const Intro = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [step, setStep] = useState(0);

  const getHeaderText = (textStep: number) => {
    if (textStep === 0) {
      return <Text>Move</Text>;
    }
    if (textStep === 1) {
      return <Text>Talk</Text>;
    }
    if (textStep === 2) {
      return <Text>Camera and Micropone</Text>;
    }
    return <Text>Screen Share</Text>;
  };

  const getText = (textStep: number) => {
    if (textStep === 0) {
      return (
        <Box>
          Use the arrow keys{' '}
          <Kbd>
            <Icon as={ChevronUpIcon} />
          </Kbd>{' '}
          <Kbd>
            <Icon as={ChevronDownIcon} />
          </Kbd>{' '}
          <Kbd>
            <Icon as={ChevronLeftIcon} />
          </Kbd>{' '}
          <Kbd>
            <Icon as={ChevronRightIcon} />
          </Kbd>{' '}
          to walk around
        </Box>
      );
    }
    if (textStep === 1) {
      return 'Walk up to people to talk to them, just like you would in real life';
    }
    if (textStep === 2) {
      return (
        <Box>
          You can turn off your camera and microphone at any time by clicking
          the bottom left{' '}
          <Kbd>
            <FontAwesomeIcon color="#000" icon={faVideo} />
          </Kbd>{' '}
          <Kbd>
            <FontAwesomeIcon color="#000" icon={faMicrophone} />
          </Kbd>{' '}
          media buttons
        </Box>
      );
    }
    return (
      <Box>
        Share your screen to other nearby users by clicking on the screen share{' '}
        <Kbd>
          <FontAwesomeIcon color="#000" icon={faDesktop} />
        </Kbd>{' '}
        button
      </Box>
    );
  };

  const completeTutorial = () => {
    onClose();
    sessionStorage.setItem(
      'MOVEMENT_CAMERA_SCREENSHARE_TUTORIAL_IS_COMPLETED',
      'completed'
    );
  };

  useEffect(() => {
    onOpen();
  }, []);

  return (
    <Box width="100%">
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="xl"
      >
        <ModalOverlay />
        <ModalContent m="16px">
          <ModalBody p="16px">
            <Box borderRadius="5px" height="280px" backgroundColor="#C4C4C4" />
            <ProgressDots step={step} pt="16px" />
          </ModalBody>

          <ModalHeader
            pt="0"
            pb="6px"
            fontSize="20px"
            fontWeight="700"
            textAlign="center"
          >
            {getHeaderText(step)}
          </ModalHeader>
          <Text
            px="60px"
            pb="6px"
            fontSize="16px"
            fontWeight="300"
            textAlign="center"
          >
            {getText(step)}
          </Text>

          <ModalFooter justifyContent="center" flexDir="column">
            <Button
              size="md"
              width="160px"
              backgroundColor="#1B3A57"
              color="white"
              onClick={() =>
                step >= 3 ? completeTutorial() : setStep((prev) => prev + 1)
              }
              _hover={{ bg: '#77a6b5' }}
            >
              <Text>{step >= 3 ? 'Finish' : 'Next'}</Text>
            </Button>

            <Button
              size="sm"
              _hover={{
                backgroundColor: 'none',
                textDecoration: 'underline',
              }}
              variant="ghost"
              color="#C4C4C4"
              onClick={completeTutorial}
            >
              Skip
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Intro;
