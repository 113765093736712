import {
  Box,
  Stack,
  HStack,
  Flex,
  Spacer,
  Text,
  Button,
  Link,
  Center,
} from '@chakra-ui/react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faVideo,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import { Decorator } from '../components/Decorator';
import { PageContainer } from '../components/PageContainer';

const Trait = ({ trait }: { trait: string }) => {
  return (
    <HStack>
      <Text fontSize="20px" color="#42E583">
        <FontAwesomeIcon icon={faCheckCircle} />
      </Text>
      <Text fontSize="12px">{trait}</Text>
    </HStack>
  );
};

const FreePlan = ({ ...rest }) => {
  const { push } = useHistory();
  const goToFreeRoomCreation = () => {
    push('/new-room');
  };
  return (
    <Box
      height="320px"
      width="240px"
      backgroundColor="#FFF"
      borderRadius="6px"
      border="3px solid #8F8F8F"
      filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
      textAlign="center"
      _hover={{ borderColor: '#2A69A5' }}
      {...rest}
    >
      <Box height="45%">
        <Text paddingTop="25px" fontSize="18px">
          Try It Out!
        </Text>
        <Text paddingTop="10px" fontSize="28px" fontWeight="700">
          Free
        </Text>
      </Box>
      <Flex direction="column" height="55%">
        <Stack marginLeft="20px" spacing="0">
          <Trait trait="Public rooms" />
          <Trait trait="Up to 8 concurrent users" />
          <Trait trait="The full Hurv experience" />
        </Stack>
        <Spacer />
        <Button
          onClick={() => {}}
          height="36px"
          margin="auto auto 24px auto"
          textColor="#fff"
          background="#1B3A57"
          _hover={{ bg: '#77a6b5' }}
        >
          <Flex>
            <Box fontSize="14px" paddingRight="10px">
              <FontAwesomeIcon icon={faVideo} />
            </Box>
            <Text onClick={goToFreeRoomCreation} color="white" fontSize="14px">
              New Room
            </Text>
          </Flex>
        </Button>
      </Flex>
    </Box>
  );
};

const DayPass = ({ ...rest }) => {
  const { push } = useHistory();
  const goToPremiumRoomCreation = () => {
    push('/order?type=day-pass');
  };
  return (
    <Box
      height="320px"
      width="240px"
      backgroundColor="#FFF"
      borderRadius="6px"
      border="3px solid #8F8F8F"
      filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
      textAlign="center"
      _hover={{ borderColor: '#2A69A5' }}
      {...rest}
    >
      <Box
        position="absolute"
        top="-3"
        left="-7"
        backgroundColor="#FBC26D"
        transform="rotate(-12deg)"
        filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
        padding="5px 10px"
      >
        <Text color="#FFF" fontSize="16px">
          Try for FREE while in Beta!
        </Text>
      </Box>
      <Box height="45%">
        <Text paddingTop="25px" fontSize="18px">
          Day Pass
        </Text>
        <Box textAlign="left" padding="10px 0 0 32%" fontWeight="700">
          <Text fontSize="28px" fontWeight="700" display="inline-block">
            $ 3
          </Text>
          <Text fontSize="14" display="inline-block" marginLeft="7px">
            / user
          </Text>
          <Text fontSize="14" fontWeight="400" marginTop="-6px">
            per day
          </Text>
        </Box>
      </Box>
      <Flex direction="column" height="55%">
        <Stack marginLeft="20px" spacing="0">
          <Trait trait="Private rooms" />
          <Trait trait="Up to 15 concurrent users" />
          <Trait trait="The full Hurv experience" />
        </Stack>
        <Spacer />
        <Button
          onClick={() => goToPremiumRoomCreation()}
          height="36px"
          margin="auto auto 24px auto"
          textColor="#fff"
          background="#1B3A57"
          _hover={{ bg: '#77a6b5' }}
        >
          <Flex>
            <Text color="white" fontSize="14px">
              Order Now
            </Text>
          </Flex>
        </Button>
      </Flex>
    </Box>
  );
};
const PermanentPlan = ({ ...rest }) => {
  const { push } = useHistory();
  const goToPremiumRoomCreation = () => {
    push('/order?type=permanent');
  };
  return (
    <Box
      height="320px"
      width="240px"
      backgroundColor="#FFF"
      borderRadius="6px"
      border="3px solid #8F8F8F"
      filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
      textAlign="center"
      _hover={{ borderColor: '#2A69A5' }}
      {...rest}
    >
      <Box
        position="absolute"
        top="-3"
        left="-7"
        backgroundColor="#FBC26D"
        transform="rotate(-12deg)"
        filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
        padding="5px 10px"
      >
        <Text color="#FFF" fontSize="16px">
          Try for FREE while in Beta!
        </Text>
      </Box>
      <Box height="45%">
        <Text paddingTop="25px" fontSize="18px">
          Permanent Space
        </Text>
        <Box textAlign="left" padding="10px 0 0 32%" fontWeight="700">
          <Text fontSize="28px" fontWeight="700" display="inline-block">
            $ 12
          </Text>
          <Text fontSize="14" display="inline-block" marginLeft="7px">
            / user
          </Text>
          <Text fontSize="14" fontWeight="400" marginTop="-6px">
            per month
          </Text>
        </Box>
      </Box>
      <Flex direction="column" height="55%">
        <Stack marginLeft="20px" spacing="0">
          <Trait trait="Private rooms" />
          <Trait trait="Up to 50 concurrent users" />
          <Trait trait="The full Hurv experience" />
        </Stack>
        <Spacer />
        <Button
          onClick={() => goToPremiumRoomCreation()}
          height="36px"
          margin="auto auto 24px auto"
          textColor="#fff"
          background="#1B3A57"
          _hover={{ bg: '#77a6b5' }}
        >
          <Flex>
            <Text color="white" fontSize="14px">
              Order Now
            </Text>
          </Flex>
        </Button>
      </Flex>
    </Box>
  );
};

export const Plans = () => {
  return (
    <PageContainer>
      <Decorator>
        <Stack paddingTop={{ base: '32px', md: '64px' }}>
          <Box fontSize="14px" padding="0 10px 28px 0">
            <Link cursor="pointer" color="teal.500" as={RouterLink} to="/">
              <HStack>
                <FontAwesomeIcon icon={faArrowLeft} />
                <Text>Home</Text>
              </HStack>
            </Link>
          </Box>
          <Center>
            <Stack direction={{ base: 'column', md: 'row' }} spacing="36px">
              <FreePlan />
              <DayPass />
              <PermanentPlan />
            </Stack>
          </Center>
        </Stack>
      </Decorator>
    </PageContainer>
  );
};

export default Plans;
