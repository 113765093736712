import { Box, BoxProps } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { mapSelector } from '../state/room';

import Hero from './Hero';
import Villager from './Villager';

import { villagerListSelector } from '../state/villagers';
import { heroIsWalkingSelector, heroPositionSelector } from '../state/hero';
import useWalk from '../hooks/useWalk';

interface MapProps extends BoxProps {
  pixelSize: number;
  griddCellPxSize: number;
}

const Map = ({ pixelSize, griddCellPxSize }: MapProps) => {
  const heroPosition = useRecoilValue(heroPositionSelector);
  const map = useRecoilValue(mapSelector);
  const villagers = useRecoilValue(villagerListSelector) as string[];
  const [gridCell, setGridCell] = useState<number>(pixelSize * griddCellPxSize);
  const isWalking = useRecoilValue(heroIsWalkingSelector);

  const Move = (_x: number, _y: number, _gridCell: number) =>
    `translate3d(${-_x * _gridCell}px, ${-_y * _gridCell}px, 0)`;

  useEffect(() => {
    setGridCell(pixelSize * griddCellPxSize);
  }, [pixelSize]);

  useWalk();

  return (
    <>
      <Box
        style={{ imageRendering: 'pixelated' }}
        backgroundImage={`url(/assets/${map.source})`}
        backgroundRepeat="no-repeat"
        backgroundSize="100%"
        position="relative"
        width={gridCell * map.hCells}
        height={gridCell * map.vCells}
        transform={Move(
          heroPosition.coordinates.x,
          heroPosition.coordinates.y,
          gridCell
        )}
        transition="linear 0.2s"
      >
        {map.overlaySource && (
          <Box
            style={{ imageRendering: 'pixelated' }}
            zIndex="1000"
            backgroundImage={`url(/assets/${map.overlaySource})`}
            backgroundRepeat="no-repeat"
            backgroundSize="100%"
            position="fixed"
            width={gridCell * map.hCells}
            height={gridCell * map.vCells}
            transition="linear 0.2s"
          />
        )}
        <Hero
          pixelSize={pixelSize}
          facing={heroPosition.facing}
          isWalking={isWalking}
          x={heroPosition.coordinates.x}
          y={heroPosition.coordinates.y}
        />
        {villagers.map((villagerId) => (
          <Villager
            key={villagerId}
            villagerId={villagerId}
            pixelSize={pixelSize}
          />
        ))}
      </Box>
    </>
  );
};

export default Map;
