import { DefaultValue, selector } from 'recoil';
import { hurvClient } from '../../api';
import {
  HERO_NAME_SESSION_KEY,
  HERO_SPRITE_SESSION_KEY,
} from '../../utils/constants';
import {
  heroIdState,
  heroPositionState,
  heroNameState,
  heroSpriteState,
  heroStatusState,
  heroEmoteState,
  heroIsWalkingState,
} from './atoms';

export const heroIdSelector = selector<Villager['id']>({
  key: 'hero-id',
  set: ({ set }, heroId) => set(heroIdState, heroId),
  get: ({ get }) => get(heroIdState),
});

export const heroNameSelector = selector<Villager['name']>({
  key: 'hero-name',
  set: ({ set }, heroName) => {
    if (heroName instanceof DefaultValue) {
      set(heroNameState, heroName);
      return;
    }
    sessionStorage.setItem(HERO_NAME_SESSION_KEY, heroName);
    set(heroNameState, heroName);
  },
  get: ({ get }) => get(heroNameState),
});

export const heroPositionSelector = selector<Villager['data']['position']>({
  key: 'hero-position',
  set: ({ set }, heroPosition) => {
    if (heroPosition instanceof DefaultValue) {
      set(heroPositionState, heroPosition);
      return;
    }
    hurvClient.move(heroPosition);
    set(heroPositionState, heroPosition);
  },
  get: ({ get }) => get(heroPositionState),
});

export const heroStatusSelector = selector<Villager['data']['status']>({
  key: 'hero-status',
  set: ({ set }, heroStatus) => set(heroStatusState, heroStatus),
  get: ({ get }) => get(heroStatusState),
});

export const heroSpriteSelector = selector<Villager['data']['sprite']>({
  key: 'hero-sprite',
  set: ({ set }, heroSprite) => {
    if (heroSprite instanceof DefaultValue) {
      set(heroSpriteState, heroSprite);
      return;
    }
    if (heroSprite) sessionStorage.setItem(HERO_SPRITE_SESSION_KEY, heroSprite);
    set(heroSpriteState, heroSprite);
  },
  get: ({ get }) => get(heroSpriteState),
});

export const heroEmoteSelector = selector<Villager['data']['emoteKey']>({
  key: 'hero-emote',
  set: ({ set }, heroEmote) => set(heroEmoteState, heroEmote),
  get: ({ get }) => get(heroEmoteState),
});

export const heroIsWalkingSelector = selector<boolean>({
  key: 'hero-is-walking',
  set: ({ set }, isWalking) => set(heroIsWalkingState, isWalking),
  get: ({ get }) => get(heroIsWalkingState),
});
