import { Box, VStack, Text, Button, BoxProps, Center } from '@chakra-ui/react';
import { useAuthContext } from '../../context/AuthContextProvider';

interface LobbyCardProps extends BoxProps {
  roomData: RoomData;
}

export const LobbyCardPrivateLoggedInRequestAccess = ({
  roomData,
}: LobbyCardProps) => {
  const { loggedInUser } = useAuthContext();
  const { isPrivate, roomName } = roomData;

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Box
      width="285px"
      height="395px"
      borderRadius="12px"
      border="1px solid #ACACAC"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      background="#FFFFFF"
      zIndex="1"
    >
      <Center height="100%">
        <VStack spacing="50px">
          <Box textAlign="center">
            <Text fontSize="14px" fontWeight="300" paddingBottom="2px">
              You are joining
            </Text>
            <Text fontSize="24px" fontWeight="400" paddingBottom="6px">
              {isPrivate ? capitalizeFirstLetter(roomName) : 'Public Room'}
            </Text>
          </Box>

          <Box px="48px" textAlign="center">
            <Text fontSize="14px" fontWeight="500">
              {loggedInUser?.name}
            </Text>
            <Text px="14px" fontSize="14px">
              does not have a desk in this office.
            </Text>
          </Box>

          <Button
            height="42px"
            width="190px"
            textColor="#fff"
            background="#1B3A57"
            margin="auto"
            onClick={() => {}}
            _hover={{ bg: '#77a6b5' }}
            disabled
          >
            <Text color="white" fontSize="16px" fontWeight="400">
              Request Access
            </Text>
          </Button>
        </VStack>
      </Center>
    </Box>
  );
};

export default LobbyCardPrivateLoggedInRequestAccess;
