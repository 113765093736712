import { useEffect, useState } from 'react';
import { hurvClient } from '../api';

export const useNotifications = () => {
  const [browserSupportsNotifications, setBrowserSupportsNotifications] =
    useState<boolean>(false);

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleBeingNotified = (payload: { from: string }) => {
    if (browserSupportsNotifications) {
      const options = {
        body: `${capitalizeFirstLetter(payload.from)} wants your attention.`,
      };
      // eslint-disable-next-line no-new
      new Notification(`Hurv`, options);
    }
  };

  useEffect(() => {
    if (!('Notification' in window)) {
      setBrowserSupportsNotifications(false);
    } else {
      setBrowserSupportsNotifications(true);
      Notification.requestPermission();
    }
    hurvClient.socket.on('NOTIFY', handleBeingNotified);
    return () => {
      hurvClient.socket.off('NOTIFY', () => handleBeingNotified);
    };
  }, [browserSupportsNotifications]);

  return null;
};

export default useNotifications;
