// @ts-nocheck
import { selectorFamily, selector, DefaultValue } from 'recoil';
import {
  chatFamilyState,
  chatMessageListState,
  unreadState,
  chatIsOpenState,
} from './atoms';

export const chatMessageFamilySelector = selectorFamily<ChatMessage>({
  key: 'chat-message-family',
  get:
    (id) =>
    ({ get }) =>
      get(chatFamilyState(id)),
  set:
    (id) =>
    ({ set }, chatMessage) => {
      set(chatFamilyState(id), chatMessage);
    },
});

export const chatMessageListSelector = selector({
  key: 'chat-message-list',
  set: ({ set }, list) => {
    if (list instanceof DefaultValue) {
      set(chatMessageListState, list);
      return;
    }

    const idList: ChatMessage['fromVillagerName'][] = [];
    list.forEach((chatMessage) => {
      idList.push(chatMessage.fromVillagerName);
      set(chatFamilyState(chatMessage.fromVillagerName), chatMessage);
    });
    set(chatMessageListState, idList);
  },
  get: ({ get }) => get(chatMessageListState),
});

export const addChatMessageSelector = selector({
  key: 'add-chat-message',
  set: ({ set, get }, chatMessage: ChatMessage) => {
    const list = get(chatMessageListState);
    set(chatMessageListState, [...list, chatMessage.messageId]);
    set(chatFamilyState(chatMessage.messageId), chatMessage);

    const chatIsOpen = get(chatIsOpenState);
    if (!chatIsOpen) {
      set(unreadState, get(unreadState) + 1);
    }
  },
});

export const unreadSelector = selector<number>({
  key: 'unread-chat-messages',
  get: ({ get }) => get(unreadState),
});

export const chatIsOpenSelector = selector<boolean>({
  key: 'chat-is-open-state',
  set: ({ set }, value) => {
    if (value === true) {
      set(unreadState, 0);
    }
    set(chatIsOpenState, value);
  },
  get: ({ get }) => get(chatIsOpenState),
});
