import { Box, Wrap, VStack, FlexProps, IconButton } from '@chakra-ui/react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { callListSelector } from '../state/calls';
import { socketIdToPeerId, usePeerContext } from '../context/PeerContext';
import { maxizedSelector } from '../state/room'; // TODO: TEMP
import { MediaFrame } from './media/MediaFrame';

export const ConnectedMedia = (props: FlexProps) => {
  const callIds = useRecoilValue(callListSelector);
  const { peerCalls } = usePeerContext();
  const setShowVideoGrid = useSetRecoilState(maxizedSelector); // TODO: TEMP

  return (
    <VStack>
      <Wrap mx="auto" justify="center" {...props}>
        {callIds.flatMap((callId) => {
          const { camera, villagerScreenShare: screenShare } =
            peerCalls[socketIdToPeerId(callId)] || {};
          return [
            camera && (
              <MediaFrame
                key={`${callId}-camera`}
                callId={callId}
                peerCall={camera}
                width="240px"
                height="150px"
              />
            ),
            screenShare && (
              <MediaFrame
                key={`${callId}-screen-share`}
                callId={callId}
                peerCall={screenShare}
                width="240px"
                height="150px"
              />
            ),
          ];
        })}
      </Wrap>
      {callIds && callIds.length > 0 && (
        <Box
          animation="rollUp 0.35s"
          borderRadius="6px"
          zIndex="1"
          backgroundColor="#3F3F3F"
        >
          <IconButton
            fontSize="12px"
            onClick={() => setShowVideoGrid((prev) => !prev)}
            aria-label="Expand"
            color="#FFF"
            variant="ghost"
            _hover={{ bg: 'transparent' }}
            icon={<FontAwesomeIcon icon={faChevronDown} />}
          />
        </Box>
      )}
    </VStack>
  );
};

export default ConnectedMedia;
