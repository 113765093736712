import { Box } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop } from '@fortawesome/free-solid-svg-icons';
import { useRecoilValue } from 'recoil';
import { useCallback } from 'react';
import { selfScreenStreamSelector } from '../../state/calls/selectors';
import useScreenShare from '../../hooks/useScreenShare';

export const ScreenShareButton = () => {
  const { initializeScreenSharing, stopScreenSharing } = useScreenShare();
  const selfScreenStream = useRecoilValue(selfScreenStreamSelector);

  const handleScreenShare = useCallback(() => {
    if (!selfScreenStream) {
      initializeScreenSharing();
    } else {
      stopScreenSharing();
    }
  }, [selfScreenStream, initializeScreenSharing, stopScreenSharing]);

  return (
    <Box
      cursor="pointer"
      width="60px"
      height="60px"
      backgroundColor="#FFF"
      borderRadius="30px"
      fontSize="26px"
      onClick={handleScreenShare}
    >
      {selfScreenStream ? (
        <Box marginLeft="16px" marginTop="10px">
          <FontAwesomeIcon color="#42E583" icon={faDesktop} />
        </Box>
      ) : (
        <Box marginLeft="16px" marginTop="10px">
          <FontAwesomeIcon color="#000" icon={faDesktop} />
        </Box>
      )}
    </Box>
  );
};

export default ScreenShareButton;
