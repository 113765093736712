import { Box } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { heroSpriteSelector } from '../state/hero';

const Player = () => {
  const sprite = useRecoilValue(heroSpriteSelector);

  return (
    <>
      <Box width="70px" height="140px" overflow="hidden">
        <Box
          animation="walkAnimation_down 1.5s steps(6) infinite"
          background={`url(/assets/characters/${sprite}_idle_anim_32x32.png) no-repeat no-repeat`}
          backgroundSize="100%"
          width="1680px"
          height="192px"
          style={{
            imageRendering: 'pixelated',
          }}
        />
      </Box>
    </>
  );
};

export default Player;
