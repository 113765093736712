import { Box, BoxProps, Center } from '@chakra-ui/react';
import { useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { selfScreenStreamSelector } from '../state/calls';

const ScreenShare = (props: BoxProps) => {
  const selfScreenStream = useRecoilValue(selfScreenStreamSelector);

  const screenShareVideoRef = useRef<HTMLVideoElement | null>(null);

  if (
    selfScreenStream &&
    screenShareVideoRef.current &&
    !screenShareVideoRef.current.srcObject
  ) {
    screenShareVideoRef.current.srcObject = selfScreenStream;
  }

  function handleCanPlay() {
    screenShareVideoRef.current?.play();
  }

  return (
    <Box borderRadius="12px" overflow="hidden" {...props}>
      <Center
        width="100%"
        height="100%"
        backgroundColor="#3F3F3F"
        display={selfScreenStream ? 'block' : 'none'}
      >
        {selfScreenStream && (
          <video
            ref={screenShareVideoRef}
            onCanPlay={handleCanPlay}
            autoPlay
            playsInline
            muted
          />
        )}
      </Center>
    </Box>
  );
};

export default ScreenShare;
