import { atom } from 'recoil';
import {
  HERO_NAME_SESSION_KEY,
  HERO_SPRITE_SESSION_KEY,
} from '../../utils/constants';
import office from '../../game/maps/office.json';

export const heroIdState = atom<Villager['id']>({
  key: 'hero-id-atom',
  default: '',
});

export const heroNameState = atom<Villager['name']>({
  key: 'hero-name-atom',
  default: sessionStorage.getItem(HERO_NAME_SESSION_KEY) || '',
});

export const heroPositionState = atom<Villager['data']['position']>({
  key: 'hero-position-atom',
  default: {
    coordinates: {
      x: office.spawn[0],
      y: office.spawn[1],
    },
    facing: office.spawnFacing,
  },
});

export const heroStatusState = atom<Villager['data']['status']>({
  key: 'hero-status-atom',
  default: 'online',
});

export const heroSpriteState = atom<Villager['data']['sprite']>({
  key: 'hero-sprite-atom',
  default: sessionStorage.getItem(HERO_SPRITE_SESSION_KEY) || 'Conference_man',
});

export const heroEmoteState = atom<Villager['data']['emoteKey']>({
  key: 'hero-emote-atom',
  default: undefined,
});

export const heroIsWalkingState = atom<boolean>({
  key: 'hero-is-walking-atom',
  default: false,
});
