import { useState } from 'react';
import { Box, Flex, Text, Spacer } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignal, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { useRecoilValue } from 'recoil';
import { mapSelector } from '../state/room';
import { villagerListSelector } from '../state/villagers';
import { callListSelector } from '../state/calls';

export const HurvHeader = () => {
  const map = useRecoilValue(mapSelector);
  const villagersTotalInRoom = useRecoilValue(villagerListSelector) as string[];
  const villagersNearby = useRecoilValue(callListSelector);
  const [connected] = useState(true);

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Flex
      width="100%"
      cursor="default"
      color="#FFFFFF"
      fontFamily="Roboto, sans-serif"
      userSelect="none"
      zIndex="1"
      p="6px"
    >
      <Text fontSize="16px">Hurv</Text>
      <Spacer />
      <Flex alignItems="center">
        <Box paddingRight="8px">
          <FontAwesomeIcon
            icon={faSignal}
            color={connected ? '#FFF' : '#EA3535'}
          />
        </Box>
        <Box paddingRight="22px">
          <Text fontSize="16px">
            {map && map.name && capitalizeFirstLetter(map.name)}
          </Text>
        </Box>
        <Box padding="0 5px" border="1px solid #FFF" borderRadius="4px">
          <Flex alignItems="center">
            <Box paddingRight="8px">
              <FontAwesomeIcon icon={faUserFriends} />
            </Box>
            <Text fontSize="12px">
              {villagersNearby.length} nearby / {villagersTotalInRoom.length}{' '}
              other users in room
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default HurvHeader;
