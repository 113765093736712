import { Box, Text, Flex, Input, Spacer } from '@chakra-ui/react';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { callListSelector } from '../../state/calls';
import { hurvClient } from '../../api';
import { chatMessageListSelector } from '../../state/chat';
import { ChatMessage } from './ChatMessage';
import { heroNameSelector, heroSpriteSelector } from '../../state/hero';

export const Chat = () => {
  const [myMessage, setMyMessage] = useState('');

  const chatMessages = useRecoilValue(chatMessageListSelector) as string[];
  const heroName = useRecoilValue(heroNameSelector);
  let heroSprite = useRecoilValue(heroSpriteSelector);

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (!heroSprite) {
      heroSprite = 'Conference_man';
    }
    hurvClient.chat({
      fromVillagerName: heroName,
      villagerSprite: heroSprite,
      message: myMessage,
    });
    setMyMessage('');
  };

  const villagersNearby = useRecoilValue(callListSelector);
  return (
    <Box backgroundColor="#FFF" height="100%" borderRadius="12px">
      <Flex height="100%" direction="column">
        <Box height="40px">
          <Text
            fontSize="12px"
            padding="10px"
            textAlign="center"
            color="#626262"
          >
            Now chatting with {villagersNearby.length} nearby users
          </Text>
        </Box>
        <Flex height="calc(100% - 40px)" direction="column">
          <Box height="100%" overflowY="auto" overflowX="hidden">
            <Box marginTop="12px">
              {chatMessages.map((messageId) => {
                return <ChatMessage key={messageId} messageId={messageId} />;
              })}
            </Box>
          </Box>
          <Spacer />

          <Box margin="12px" as="form" onSubmit={handleSubmit}>
            <Input
              userSelect="none"
              textColor="#1B3A57"
              borderColor="#1B3A57"
              placeholder="Send a message to room"
              value={myMessage}
              onChange={(event) => setMyMessage(event.target.value)}
              onSubmit={handleSubmit}
            />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Chat;
