import Peer from 'peerjs';
import { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { hurvClient } from '../api';
import { socketIdToPeerId, usePeerContext } from '../context/PeerContext';
import { callListSelector, selfScreenStreamSelector } from '../state/calls';

const useScreenShare = () => {
  const {
    peerServerConnection,
    addPeerCall,
    removeScreenSharePeerCall,
    peerCalls,
  } = usePeerContext();
  const callIds = useRecoilValue(callListSelector);
  const [selfScreenStream, setSelfScreenStream] = useRecoilState(
    selfScreenStreamSelector
  );

  const createScreenShareCall = useCallback(
    (callId: string, screenStream: MediaStream): Peer.MediaConnection => {
      if (peerServerConnection === null)
        throw new Error('No peer server connection when sharing screen');
      if (!screenStream)
        throw new Error('No screen stream when sharing screen');

      const screenShareCall = peerServerConnection.call(
        socketIdToPeerId(callId),
        screenStream,
        {
          metadata: {
            isScreenShare: true,
            screenShareHost: peerServerConnection.id,
          },
        }
      );
      addPeerCall(screenShareCall, true);
      return screenShareCall;
    },
    [peerServerConnection, addPeerCall]
  );

  const removeScreenShareCall = ({ villagerId }: { villagerId: string }) => {
    removeScreenSharePeerCall(villagerId);
  };

  const stopScreenSharing = useCallback(() => {
    Object.values(peerCalls).forEach((peerCall) => {
      if (peerCall.heroScreenShare) {
        peerCall.heroScreenShare.close();
      }

      selfScreenStream?.getVideoTracks().forEach((track) => track.stop());
      setSelfScreenStream(null);
    });
    hurvClient.stopScreenSharing();
  }, [selfScreenStream, peerCalls]);

  const initializeScreenSharing = useCallback(() => {
    if (peerServerConnection === null)
      throw new Error(
        'No peer server connection when initializing screen sharing'
      );

    navigator.mediaDevices
      // @ts-ignore
      .getDisplayMedia({
        video: { frameRate: 10, width: 1280, height: 720 },
      })
      .then((screenStream: MediaStream) => {
        screenStream.getVideoTracks().forEach((videoTrack) => {
          // eslint-disable-next-line no-param-reassign
          videoTrack.onended = stopScreenSharing;
        });
        setSelfScreenStream(screenStream);
        callIds.forEach((callId) => {
          createScreenShareCall(callId, screenStream);
        });
      });
  }, [peerServerConnection, callIds, createScreenShareCall]);

  return {
    createScreenShareCall,
    removeScreenShareCall,
    initializeScreenSharing,
    stopScreenSharing,
  };
};

export default useScreenShare;
