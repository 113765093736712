import { Box } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMicrophone,
  faMicrophoneSlash,
} from '@fortawesome/free-solid-svg-icons';
import { useRecoilState } from 'recoil';
import { selfIsMicrophoneEnabledSelector } from '../../state/calls/selectors';

export const MicrophoneButton = () => {
  const [isMicrophoneEnabled, toggleMicrophone] = useRecoilState(
    selfIsMicrophoneEnabledSelector
  );

  return (
    <Box
      cursor="pointer"
      width="60px"
      height="60px"
      backgroundColor="#FFF"
      borderRadius="30px"
      fontSize="30px"
      marginRight="12px"
      onClick={() => toggleMicrophone((prev) => !prev)}
    >
      {isMicrophoneEnabled ? (
        <Box marginLeft="20px" marginTop="7px">
          <FontAwesomeIcon color="#000" icon={faMicrophone} />
        </Box>
      ) : (
        <Box marginLeft="12px" marginTop="7px">
          <FontAwesomeIcon color="#EA3535" icon={faMicrophoneSlash} />
        </Box>
      )}
    </Box>
  );
};

export default MicrophoneButton;
