import { atomFamily, atom } from 'recoil';

export const villagerListState = atom<Villager['id'][]>({
  key: 'villager-list-atom',
  default: [],
});

export const villagerFamilyState = atomFamily<Villager | undefined, string>({
  key: 'villager-list-state',
  default: undefined,
});
