/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  isWebRTCSupported,
  isWebsiteHasWebcamPermissions,
  isWebsiteHasMicrophonePermissions,
  hasWebcam,
  hasMicrophone,
  load as initDetectRTC,
} from 'detectrtc';
import { selfStreamSelector } from '../state/calls';
import {
  hasAllowedCameraSelector,
  hasAllowedMicrophoneSelector,
  hasConnectedCameraSelector,
  hasConnectedMicrophoneSelector,
  hasWebRTCSupportSelector,
  hasFinishedSettingPermissionsSelector,
} from '../state/access';

const CAPTURE_OPTIONS = {
  audio: true,
  video: {
    facingMode: 'user',
    width: 640,
    height: 480,
    frameRate: { ideal: 12, max: 15 },
  },
};

const useCall = () => {
  const [selfMediaStream, setSelfMediaStream] =
    useRecoilState(selfStreamSelector);
  const setIsWebRTCSupported = useSetRecoilState(hasWebRTCSupportSelector);
  const setIsWebsiteHasWebcamPermissions = useSetRecoilState(
    hasAllowedCameraSelector
  );
  const setIsWebsiteHasMicrophonePermissions = useSetRecoilState(
    hasAllowedMicrophoneSelector
  );
  const setHasWebcam = useSetRecoilState(hasConnectedCameraSelector);
  const setHasMicrophone = useSetRecoilState(hasConnectedMicrophoneSelector);
  const setHasFinishedSettingPermissions = useSetRecoilState(
    hasFinishedSettingPermissionsSelector
  );

  useEffect(() => {
    if (!selfMediaStream) {
      navigator.mediaDevices.getUserMedia(CAPTURE_OPTIONS).then((newStream) => {
        setSelfMediaStream(newStream);
      });
    }
  }, []);

  useEffect(() => {
    initDetectRTC(() => {
      setIsWebRTCSupported(isWebRTCSupported);
      setIsWebsiteHasWebcamPermissions(isWebsiteHasWebcamPermissions);
      setIsWebsiteHasMicrophonePermissions(isWebsiteHasMicrophonePermissions);
      setHasWebcam(hasWebcam);
      setHasMicrophone(hasMicrophone);
    });
    setHasFinishedSettingPermissions(true);
  }, []);

  return null;
};

export default useCall;
