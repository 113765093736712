import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { parse } from 'query-string';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Stack, Spinner, Center, useToast } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';

import Camera from '../components/Camera';
import useCamera from '../hooks/useCamera';
import { Decorator } from '../components/Decorator';
import { useAuthContext } from '../context/AuthContextProvider';
import { LobbyCardCanEnter } from '../components/LobbyCards/LobbyCardCanEnter';
import { LobbyCardPrivateLoggedOut } from '../components/LobbyCards/LobbyCardPrivateLoggedOut';
import { LobbyCardPrivateLoggedInRequestAccess } from '../components/LobbyCards/LobbyCardPrivateLoggedInRequestAccess';
import { PageContainer } from '../components/PageContainer';
import {
  hasAllowedCameraSelector,
  hasAllowedMicrophoneSelector,
  hasConnectedCameraSelector,
  hasConnectedMicrophoneSelector,
  hasFinishedSettingPermissionsSelector,
  hasWebRTCSupportSelector,
} from '../state/access/selectors';

export const SelectHero = () => {
  useCamera();
  const toast = useToast();
  const hasFinishedSettingPermissions = useRecoilValue(
    hasFinishedSettingPermissionsSelector
  );
  const hasAllowedCamera = useRecoilValue(hasAllowedCameraSelector);
  const hasAllowedMicrophone = useRecoilValue(hasAllowedMicrophoneSelector);
  const hasWebRTCSupport = useRecoilValue(hasWebRTCSupportSelector);
  const hasConnectedCamera = useRecoilValue(hasConnectedCameraSelector);
  const hasConnectedMicrophone = useRecoilValue(hasConnectedMicrophoneSelector);

  const { roomId } = useParams<{ roomId: string }>();
  const urlParams = parse(window.location.search);
  const { idToken } = useAuthContext();
  const [roomData, setRoomData] = useState<RoomData>();
  const setInviteCode = () => {
    if (urlParams.invite) {
      if (urlParams.invite instanceof Array) {
        return urlParams.invite[0];
      }
      return urlParams.invite;
    }
    return undefined;
  };
  const [inviteCode] = useState<string | undefined>(setInviteCode());
  const [isLoadingRoomData, setIsLoadingRoomData] = useState(true);
  const [isLoadingCameraAccess, setIsLoadingCameraAccess] = useState(true);
  const [hasRequiredAccessToConnect, setHasRequiredAccessToConnect] =
    useState(true);
  const { push } = useHistory();

  const goToHome = () => {
    push(`/`);
  };

  const requireLogin = () => {
    return roomData?.isPrivate && !idToken;
  };

  const isLoggedIn = () => {
    return !!idToken;
  };

  const isAllowedToConnect = () => {
    return roomData?.userIsAllowed && !roomData.error;
  };

  useEffect(() => {
    if (roomData?.roomIsExpired && !roomData.error) {
      push(`/space-expired`);
    }
  }, [roomData]);

  const newToastError = (title: string, description: string) => {
    setHasRequiredAccessToConnect(false);
    toast({
      title,
      description,
      status: 'error',
      duration: 12000,
      isClosable: true,
    });
  };

  useEffect(() => {
    if (
      hasFinishedSettingPermissions &&
      hasAllowedCamera !== undefined &&
      hasAllowedMicrophone !== undefined &&
      hasWebRTCSupport !== undefined &&
      hasConnectedCamera !== undefined &&
      hasConnectedMicrophone !== undefined
    ) {
      if (hasWebRTCSupport === false) {
        newToastError(
          'Browser not supported',
          'Please use Chrome, Firefox, Opera, Edge or Android.'
        );
      } else {
        if (hasAllowedCamera === false) {
          newToastError(
            'Error accessing camera',
            'Please allow Hurv to access your camera. (You might need to refresh the page afterwards)'
          );
        }
        if (hasAllowedMicrophone === false) {
          newToastError(
            'Error accessing microphone',
            'Please allow Hurv to access microphone. (You might need to refresh the page afterwards)'
          );
        }
        if (hasConnectedCamera === false) {
          newToastError(
            'No camera found',
            'Please connect a webcamera. (You might need to refresh the page afterwards)'
          );
        }
        if (hasConnectedMicrophone === false) {
          newToastError(
            'No microphone found',
            'Please connect a microphone. (You might need to refresh the page afterwards)'
          );
        }
      }
      setIsLoadingCameraAccess(false);
    }
  }, [
    hasFinishedSettingPermissions,
    hasAllowedCamera,
    hasAllowedMicrophone,
    hasWebRTCSupport,
    hasConnectedCamera,
    hasConnectedMicrophone,
  ]);

  useEffect(() => {
    setIsLoadingRoomData(true);
    const urlWithParams = new URL(
      `${process.env.REACT_APP_API_URL}api/join-room`
    );
    if (roomId) {
      urlWithParams.searchParams.append('room', roomId);
    }
    if (inviteCode) {
      urlWithParams.searchParams.append('invite', inviteCode);
    }
    fetch(`${urlWithParams}`, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken || 'unauthenticated'}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        if (res.status === 404) {
          goToHome();
        }
        if (res.status === 401) {
          return res.json();
        }
        throw new Error(res.statusText);
      })
      .then((data) => setRoomData(data))
      .catch((error) => {
        console.error(error);
        setIsLoadingRoomData(false);
      })
      .finally(() => {
        setIsLoadingRoomData(false);
      });
  }, [idToken]);

  return (
    <PageContainer>
      <Decorator>
        <Center mt="64px" height="75vh">
          {(isLoadingRoomData || isLoadingCameraAccess) && <Spinner />}
          {!(isLoadingRoomData || isLoadingCameraAccess) && (
            <Stack
              justify="center"
              direction={{ base: 'column', md: 'row' }}
              spacing="24px"
            >
              <Camera
                width={{ base: '283px', md: '400px', lg: '600px' }}
                height={{ base: '214px', md: '400px', lg: '400px' }}
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              />
              {roomData && isAllowedToConnect() && (
                <LobbyCardCanEnter
                  margin="auto"
                  roomData={roomData}
                  canEnter={hasRequiredAccessToConnect}
                />
              )}
              {roomData && !isAllowedToConnect() && isLoggedIn() && (
                <LobbyCardPrivateLoggedInRequestAccess roomData={roomData} />
              )}
              {roomData && requireLogin() && (
                <LobbyCardPrivateLoggedOut roomData={roomData} />
              )}
            </Stack>
          )}
        </Center>
      </Decorator>
    </PageContainer>
  );
};

export default SelectHero;
