import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import {
  Box,
  Flex,
  Text,
  Button,
  Input,
  IconButton,
  BoxProps,
  HStack,
  Center,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import sprites from '../../game/sprites.json';
import PlayerPreview from '../PlayerPreview';
import { heroNameSelector, heroSpriteSelector } from '../../state/hero';
import { useAuthContext } from '../../context/AuthContextProvider';

interface LobbyCardProps extends BoxProps {
  roomData: RoomData;
  canEnter: boolean;
}

interface UserTokenProps extends BoxProps {
  onlineUsers: number | undefined;
  users: number | undefined;
}

const UserToken = ({ onlineUsers, users }: UserTokenProps) => (
  <HStack justify="center" spacing="6px">
    {onlineUsers && onlineUsers > 0 && (
      <Box borderRadius="50px" padding="4px 8px" backgroundColor="#1B3A57">
        <HStack spacing="6px">
          <Box
            width="9px"
            height="9px"
            borderRadius="12px"
            backgroundColor="#42E583"
          />
          <Text fontSize="10px" fontWeight="500" color="#FFF">
            {onlineUsers} Online
          </Text>
        </HStack>
      </Box>
    )}
    {users && users > 0 && (
      <Box borderRadius="50px" padding="4px 8px" backgroundColor="#1B3A57">
        <HStack spacing="6px">
          <Box
            width="9px"
            height="9px"
            borderRadius="12px"
            backgroundColor="#9E9E9E"
          />
          <Text fontSize="10px" fontWeight="500" color="#FFF">
            {users} Members
          </Text>
        </HStack>
      </Box>
    )}
  </HStack>
);

export const LobbyCardCanEnter = ({ roomData, canEnter }: LobbyCardProps) => {
  const { isPrivate, roomName } = roomData;
  const [name, setName] = useRecoilState(heroNameSelector);
  const setSprite = useSetRecoilState(heroSpriteSelector);
  const [spriteIndex, setSpriteIndex] = useState(0);
  const { loggedInUser } = useAuthContext();

  const { push } = useHistory();
  const enterRoom = () => {
    // setLoading(true); // emit to parent and handle
    if (roomName !== '') {
      push(`/${roomName}`);
    } else {
      push(`/${uuid()}`);
    }
  };

  useEffect(() => {
    if (loggedInUser?.name) {
      setName(loggedInUser.name);
    }
  }, [loggedInUser]);

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const changeCharacterSprite = (number: number) => {
    if (spriteIndex + number === sprites.length) {
      setSprite(sprites[0]);
      setSpriteIndex(0);
    } else if (spriteIndex + number < 0) {
      setSprite(sprites[sprites.length - 1]);
      setSpriteIndex(sprites.length - 1);
    } else {
      setSprite(sprites[spriteIndex + number]);
      setSpriteIndex(spriteIndex + number);
    }
  };
  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    enterRoom();
  };
  return (
    <Box
      width="285px"
      height="395px"
      borderRadius="12px"
      border="1px solid #ACACAC"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      background="#FFFFFF"
      zIndex="1"
    >
      <Center height="100%">
        <Flex direction="column">
          <Box textAlign="center">
            <Text fontSize="14px" fontWeight="300" paddingBottom="2px">
              You are joining
            </Text>
            <Text fontSize="24px" fontWeight="400" paddingBottom="6px">
              {isPrivate ? capitalizeFirstLetter(roomName) : 'Public Room'}
            </Text>
            <UserToken
              onlineUsers={roomData.onlineUsers}
              users={roomData.users}
            />
          </Box>
          <Flex direction="row" margin="0 auto 8px auto">
            <Box marginTop="80px">
              <IconButton
                onClick={() => changeCharacterSprite(-1)}
                aria-label="Change sprite"
                variant="ghost"
                _hover={{ bg: 'transparent' }}
                icon={<FontAwesomeIcon icon={faArrowLeft} />}
              />
            </Box>
            <Box margin="auto 20px">
              <PlayerPreview />
            </Box>
            <Box marginTop="80px">
              <IconButton
                onClick={() => changeCharacterSprite(1)}
                aria-label="Change sprite"
                variant="ghost"
                _hover={{ bg: 'transparent' }}
                icon={<FontAwesomeIcon icon={faArrowRight} />}
              />
            </Box>
          </Flex>
          <Box
            as="form"
            height="42px"
            width="190px"
            margin="6px auto"
            marginTop="10px"
            onSubmit={handleSubmit}
          >
            <Input
              height="100%"
              background="#fff"
              textColor="#1B3A57"
              borderColor="#1B3A57"
              _hover={{ borderColor: '#77a6b5' }}
              placeholder="Enter your name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              autoFocus
            />
          </Box>
          <Button
            onClick={enterRoom}
            height="42px"
            width="190px"
            textColor="#fff"
            background="#1B3A57"
            margin="auto"
            _hover={{ bg: '#77a6b5' }}
            disabled={!canEnter}
          >
            <Text color="white" fontSize="16px" fontWeight="400">
              Join now
            </Text>
          </Button>
        </Flex>
      </Center>
    </Box>
  );
};

export default LobbyCardCanEnter;
