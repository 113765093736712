/* eslint-disable jsx-a11y/media-has-caption */
import { useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  Box,
  HStack,
  VStack,
  Spinner,
  Center,
  Text,
  Button,
  Input,
  Wrap,
  BoxProps,
  Stack,
  Image,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faVideo,
  faUserFriends,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { Decorator } from '../components/Decorator';
import { PageContainer } from '../components/PageContainer';

export const HomePage = () => {
  const [loading] = useState(false);
  const { push } = useHistory();

  const goToPricing = () => {
    push(`/plans`);
  };

  const RoomButtons = (props: BoxProps) => {
    const [roomId, setRoomId] = useState('');
    const [showMenu, setShowMenu] = useState(false);

    const goToLobby = (room: string) => {
      if (room && room !== '') {
        push(`/lobby/${room}`);
      } else {
        console.error('No room ID');
      }
    };

    const goToFreeRoomCreation = () => {
      push('/new-room');
    };

    const goToPaidRoomCreation = () => {
      push('/order');
    };

    const handleSubmit = (event: { preventDefault: () => void }) => {
      event.preventDefault();
      goToLobby(roomId);
    };

    return (
      <VStack
        {...props}
        spacing={{ base: '16px', md: '24px' }}
        textAlign="center"
      >
        <Wrap spacing="12px">
          <Box position="relative">
            <Button
              onClick={() => setShowMenu(true)}
              height={{ base: '38px', md: '48px' }}
              textColor="#fff"
              background="#1B3A57"
              _hover={{ bg: '#77a6b5' }}
            >
              <HStack>
                <Box paddingRight="10px">
                  <FontAwesomeIcon icon={faVideo} />
                </Box>
                <Text color="white" fontSize="16px">
                  New Space
                </Text>
              </HStack>
            </Button>
            {showMenu && (
              <VStack
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                spacing="0"
                alignItems="flex-start"
                top="-3px"
                right="-3px"
                backgroundColor="#FFF"
                borderRadius="5px"
                border="1px solid #ACACAC"
                position="absolute"
              >
                <Button
                  onClick={() => goToFreeRoomCreation()}
                  height={{ base: '38px', md: '48px' }}
                  textColor="#000"
                  _hover={{ bg: '#F0F0F0' }}
                  variant="ghost"
                  width="100%"
                  borderRadius="5px 5px 0 0"
                >
                  <HStack>
                    <Box paddingRight="10px">
                      <FontAwesomeIcon icon={faUserFriends} />
                    </Box>
                    <Text color="#000" fontSize="16px">
                      Free small space
                    </Text>
                  </HStack>
                </Button>
                <Button
                  onClick={() => goToPaidRoomCreation()}
                  height={{ base: '38px', md: '48px' }}
                  textColor="#000"
                  _hover={{ bg: '#F0F0F0' }}
                  variant="ghost"
                  width="100%"
                  borderRadius="0 0 5px 5px"
                >
                  <HStack marginRight="auto">
                    <Box paddingRight="10px">
                      <FontAwesomeIcon icon={faUsers} />
                    </Box>
                    <Text color="#000" fontSize="16px">
                      Premium space
                    </Text>
                  </HStack>
                </Button>
              </VStack>
            )}
          </Box>
          <Center>
            <Text fontWeight="700" fontSize={{ base: '13px', md: '16px' }}>
              or
            </Text>
          </Center>
          <HStack>
            <Box as="form" onSubmit={handleSubmit}>
              <Input
                onFocus={() => setShowMenu(false)}
                height={{ base: '38px', md: '48px' }}
                background="#fff"
                width="170px"
                textColor="#1B3A57"
                borderColor="#1B3A57"
                _hover={{ borderColor: '#77a6b5' }}
                placeholder="Enter a room code"
                value={roomId}
                onChange={(event) => setRoomId(event.target.value)}
              />
            </Box>
            {roomId !== '' && (
              <Button
                colorScheme="none"
                onClick={() => goToLobby(roomId)}
                height={{ base: '38px', md: '48px' }}
                width="40px"
                padding="0"
              >
                <Text color="#1B3A57" fontSize="16px">
                  Join
                </Text>
              </Button>
            )}
          </HStack>
        </Wrap>
        <Text
          textAlign={{ base: 'left', md: 'center' }}
          textDecoration="underline"
          color="#9E9E9E"
          cursor="pointer"
          fontSize={{ base: '13px', md: '16px' }}
          onClick={goToPricing}
          _hover={{
            textDecoration: 'underline',
            fontWeight: '500',
            color: '#474747',
          }}
        >
          View pricing and plans
        </Text>
      </VStack>
    );
  };

  const SalesPoints = (props: BoxProps) => {
    return (
      <Box {...props}>
        <VStack
          spacing={{ base: '40px', md: '48px' }}
          pt={{ base: '40px', md: '48px' }}
          pb={{ base: '20px', md: '48px' }}
        >
          <Stack
            direction={{ base: 'column', md: 'row' }}
            animation="rollUp 0.4s"
            maxWidth="930px"
            margin="auto"
            spacing={{ base: '0', md: '48px' }}
          >
            <VStack display={{ base: 'none', md: 'flex' }}>
              <Box
                maxWidth="500px"
                margin={{ base: 'auto auto auto 0', md: 'auto' }}
              >
                <Text
                  fontWeight="700"
                  color="#1B3A57"
                  fontSize={{ base: '22px', md: '28px' }}
                  pb={{ base: '8px', md: '12px' }}
                >
                  Don&#39;t write emails, say hello!
                </Text>
                <Text fontSize={{ base: '13px', md: '16px' }}>
                  Seamlessly walk in and out of conversations. Talking to
                  colleagues remotely have never been more natural.
                </Text>
              </Box>
            </VStack>

            <Image
              width="460px"
              height="260px"
              objectFit="cover"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              borderRadius="12px"
              border="4px solid #ACACAC"
              src="/assets/promo/say_hi.png"
              style={{
                imageRendering: 'pixelated',
              }}
            />
            <VStack
              display={{ base: 'flex', md: 'none' }}
              py={{ base: '20px', md: '0' }}
            >
              <Box
                maxWidth="500px"
                margin={{ base: 'auto auto auto 0', md: 'auto' }}
              >
                <Text
                  fontWeight="700"
                  color="#1B3A57"
                  fontSize={{ base: '22px', md: '28px' }}
                  pb={{ base: '8px', md: '12px' }}
                >
                  Don&#39;t write emails, say hello!
                </Text>
                <Text fontSize={{ base: '13px', md: '16px' }}>
                  Seamlessly walk in and out of conversations. Talking to
                  colleagues remotely have never been more natural.
                </Text>
              </Box>
            </VStack>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            animation="rollUp 0.4s"
            maxWidth="930px"
            margin="auto"
            spacing={{ base: '0', md: '48px' }}
          >
            {false && (
              <video playsInline autoPlay muted loop width="100%">
                <source
                  src="https://dl8.webmfiles.org/big-buck-bunny_trailer.webm"
                  type="video/webm"
                />
              </video>
            )}
            {true && (
              <Image
                width="460px"
                height="260px"
                objectFit="cover"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                borderRadius="12px"
                border="4px solid #ACACAC"
                src="/assets/promo/meeting.png"
                style={{
                  imageRendering: 'pixelated',
                }}
              />
            )}

            <VStack py={{ base: '20px', md: '0' }}>
              <Box
                maxWidth="500px"
                margin={{ base: 'auto auto auto 0', md: 'auto' }}
              >
                <Text
                  fontWeight="700"
                  color="#1B3A57"
                  fontSize={{ base: '22px', md: '28px' }}
                  pb={{ base: '8px', md: '12px' }}
                >
                  Gather for meetings
                </Text>
                <Text fontSize={{ base: '13px', md: '16px' }}>
                  Simply walk to your meeting room. With Hurv you can finally
                  say goodbye to links and invitations. You can focus on what
                  matters, without the technical overhead.
                </Text>
              </Box>
            </VStack>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            animation="rollUp 0.4s"
            maxWidth="930px"
            margin="auto"
            spacing={{ base: '0', md: '48px' }}
          >
            <VStack display={{ base: 'none', md: 'flex' }}>
              <Box
                maxWidth="500px"
                margin={{ base: 'auto auto auto 0', md: 'auto' }}
              >
                <Text
                  fontWeight="700"
                  color="#1B3A57"
                  fontSize={{ base: '22px', md: '28px' }}
                  pb={{ base: '8px', md: '12px' }}
                >
                  Hold a presentation
                </Text>
                <Text fontSize={{ base: '13px', md: '16px' }}>
                  Share your screen, and hold a large scale presentation to your
                  entire business. All within in the same virtual office space.
                </Text>
              </Box>
            </VStack>
            <Image
              width="460px"
              height="260px"
              objectFit="cover"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              borderRadius="12px"
              border="4px solid #ACACAC"
              src="/assets/promo/conference.png"
              style={{
                imageRendering: 'pixelated',
              }}
            />
            <VStack
              display={{ base: 'flex', md: 'none' }}
              py={{ base: '20px', md: '0' }}
            >
              <Box
                maxWidth="500px"
                margin={{ base: 'auto auto auto 0', md: 'auto' }}
              >
                <Text
                  fontWeight="700"
                  color="#1B3A57"
                  fontSize={{ base: '22px', md: '28px' }}
                  pb={{ base: '8px', md: '12px' }}
                >
                  Hold a presentation
                </Text>
                <Text fontSize={{ base: '13px', md: '16px' }}>
                  Share your screen, and hold a large scale presentation to your
                  entire business. All within in the same virtual office space.
                </Text>
              </Box>
            </VStack>
          </Stack>
        </VStack>
      </Box>
    );
  };

  const DesktopAndMobile = (props: BoxProps) => {
    return (
      <Box {...props} animation="rollUp 0.4s" spacing="48px">
        <VStack my={{ base: '40px', md: '60px' }} textAlign="center">
          <Text fontSize={{ base: '29px', md: '36px' }} fontWeight="700">
            At your desk, and on the go.
          </Text>
          <Text
            maxWidth="475px"
            paddingBottom={{ base: '16px', md: '36px' }}
            fontSize={{ base: '16px', md: '20px' }}
            fontWeight="300"
          >
            Accessibility on your own terms. Being present at work has never
            been easier.
          </Text>
          <Image src="/assets/desktop_mobile.png" width="100%" height="auto" />
        </VStack>
      </Box>
    );
  };

  return (
    <PageContainer>
      <Decorator>
        {loading && (
          <Center height="100%">
            <Spinner />
          </Center>
        )}
        {!loading && (
          <Box>
            <Box>
              <VStack
                display={{ base: 'block', md: 'flex' }}
                textAlign={{ base: 'left', md: 'center' }}
                paddingTop={{ base: '58px', md: '72px' }}
                paddingBottom={{ base: '40px', md: '48px' }}
              >
                <Text
                  fontSize={{ base: '29px', md: '36px' }}
                  fontWeight="700"
                  textShadow="-2px -2px 0 #FFF, 2px -2px 0 #FFF, -2px 2px 0 #FFF, 2px 2px 0 #FFF"
                >
                  Communicate better
                </Text>
                <Text
                  maxWidth="400px"
                  paddingBottom={{ base: '16px', md: '36px' }}
                  fontSize={{ base: '16px', md: '20px' }}
                  fontWeight="300"
                  textShadow="-2px -2px 0 #FFF, 2px -2px 0 #FFF, -2px 2px 0 #FFF, 2px 2px 0 #FFF"
                >
                  With virtual offices, Hurv makes online conversation feel as
                  natural as real life.
                </Text>
                <RoomButtons display={{ base: 'block', md: 'flex' }} />
              </VStack>
            </Box>
            <SalesPoints
              position="relative"
              _before={{
                content: '""',
                backgroundColor: '#F0F0F0',
                position: 'absolute',
                height: '100%',
                width: '600%',
                left: '-250%',
                zIndex: '-1',
              }}
            />
            <DesktopAndMobile />
            <Box
              animation="rollUp 0.4s"
              paddingTop={{ base: '36px', md: '60px' }}
              paddingBottom={{ base: '80px', md: '120px' }}
            >
              <Text
                paddingBottom={{ base: '16px', md: '36px' }}
                textAlign="center"
                fontSize={{ base: '23px', md: '28px' }}
                fontWeight="700"
              >
                Ready to start?
              </Text>
              <RoomButtons />
            </Box>
          </Box>
        )}
      </Decorator>
    </PageContainer>
  );
};

export default HomePage;
