import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { hurvClient } from '../api';
import { heroStatusSelector } from '../state/hero/selectors';
import {
  isFocusedSelector,
  roomIsLoadingSelector,
} from '../state/room/selectors';

const useRoom = () => {
  const setIsLoading = useSetRecoilState(roomIsLoadingSelector);
  const [isFocused, setIsFocused] = useRecoilState(isFocusedSelector);
  const heroStatus = useRecoilValue<string>(heroStatusSelector);
  const { push } = useHistory();
  const handleConnectonRejected = ({
    roomId,
    reason,
  }: {
    roomId: string;
    reason: string;
  }) => {
    if (reason === 'room-does-not-exist') {
      push(`/`);
    }
    if (reason === 'not-allowed') {
      push(`/lobby/${roomId}`);
    }
    if (reason === 'room-is-full') {
      push(`/lobby/${roomId}`);
    }
    if (reason === 'room-is-expired') {
      push(`/space-expired`);
    }
  };

  const handleConnectonEstablished = () => {
    setIsLoading(false);
  };

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    const isFocusedOnLoad = document.hasFocus();
    setIsFocused(isFocusedOnLoad);
  }, []);

  useEffect(() => {
    if (!isFocused) {
      hurvClient.setStatus('blurred');
    } else {
      hurvClient.setStatus(heroStatus);
    }
  }, [isFocused]);

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    hurvClient.socket.on('CONNECTION_REJECTED', handleConnectonRejected);
    hurvClient.socket.on('CONNECTION_ESTABLISHED', handleConnectonEstablished);
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
      hurvClient.socket.off('CONNECTION_REJECTED', handleConnectonRejected);
      hurvClient.socket.off(
        'CONNECTION_ESTABLISHED',
        handleConnectonEstablished
      );
    };
  }, [hurvClient]);
  return null;
};

export default useRoom;
