import { Box } from '@chakra-ui/react';
import { ReactNode } from 'react';

const grid =
  'data:image/svg+xml;base64,PHN2ZyBvcGFjaXR5PSIwLjIyIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgICAgIDxkZWZzPgogICAgICAgICAgPHBhdHRlcm4gaWQ9InNtYWxsR3JpZCIgd2lkdGg9IjgiIGhlaWdodD0iOCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0gOCAwIEwgMCAwIDAgOCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJncmF5Ij48L3BhdGg+CiAgICAgICAgICA8L3BhdHRlcm4+CiAgICAgICAgICA8cGF0dGVybiBpZD0iZ3JpZCIgd2lkdGg9IjgwIiBoZWlnaHQ9IjgwIiBwYXR0ZXJuVW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KICAgICAgICAgICAgPHJlY3Qgd2lkdGg9IjgwIiBoZWlnaHQ9IjgwIiBmaWxsPSJ1cmwoI3NtYWxsR3JpZCkiPjwvcmVjdD4KICAgICAgICAgICAgPHBhdGggZD0iTSA4MCAwIEwgMCAwIDAgODAiIGZpbGw9Im5vbmUiIHN0cm9rZT0iZ3JheSI+PC9wYXRoPgogICAgICAgICAgPC9wYXR0ZXJuPgogICAgICAgIDwvZGVmcz4KCiAgICAgICAgPHJlY3Qgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmlkKSI+PC9yZWN0PgogICAgICA8L3N2Zz4=';

export const PageContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Box height="100%" backgroundImage={`url(${grid})`} overflowX="hidden">
      <Box
        height="100%"
        maxWidth="930px"
        px="24px"
        margin="auto"
        fontFamily="Roboto, sans-serif"
      >
        {children}
      </Box>
    </Box>
  );
};

export default PageContainer;
