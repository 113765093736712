import { Box } from '@chakra-ui/react';

import { useRecoilValue } from 'recoil';
import { socketIdToPeerId, usePeerContext } from '../../context/PeerContext';

import { callListSelector } from '../../state/calls';
import ResizeableGrid from '../RezisableGrid/ResizableGrid';

export const MaximisedMediaGrid = () => {
  const callIds = useRecoilValue(callListSelector);
  const { peerCalls } = usePeerContext();

  return (
    <Box width="100%" height="100%">
      <ResizeableGrid
        height="100%"
        mediaFrames={callIds.flatMap((callId) => {
          const { camera, villagerScreenShare: screenShare } =
            peerCalls[socketIdToPeerId(callId)] || {};
          const streams = [];
          if (camera) {
            streams.push({
              key: `${callId}-camera`,
              callId,
              peerCall: camera,
            });
          }
          if (screenShare) {
            streams.push({
              key: `${callId}-screen-share`,
              callId,
              peerCall: screenShare,
            });
          }
          return streams;
        })}
      />
    </Box>
  );
};

export default MaximisedMediaGrid;
