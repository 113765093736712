import firebase from 'firebase';

export default class Auth {
  constructor() {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: 'townie-app.firebaseapp.com',
      projectId: 'townie-app',
      storageBucket: 'townie-app.appspot.com',
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    };
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  }
}
