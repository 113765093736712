import { Box, Text, IconButton, BoxProps, Center } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Chat } from '../chat/Chat';
import { chatIsOpenSelector, unreadSelector } from '../../state/chat';

export const ChatButton = (props: BoxProps) => {
  const [expanded, setExpanded] = useRecoilState(chatIsOpenSelector);
  const unread = useRecoilValue(unreadSelector);

  return (
    <Box
      width={expanded ? '350px' : '50px'}
      height={expanded ? '70vh' : '50px'}
      backgroundColor="#FFF"
      borderRadius={expanded ? '12px' : '25px'}
      display={expanded ? 'inherit' : 'grid'}
      placeItems={expanded ? '' : 'center'}
      transition=".1s"
      border="1px solid #1B3A57"
      {...props}
    >
      {!expanded && (
        <Box>
          <Center
            onClick={() => setExpanded(!expanded)}
            fontSize="35px"
            width="35px"
            height="35px"
            color="#1B3A57"
            cursor="pointer"
            position="relative"
          >
            <FontAwesomeIcon icon={faComment} />
            {unread > 0 && (
              <Box
                onClick={() => setExpanded(!expanded)}
                backgroundColor="#FFF"
                width="16px"
                height="16px"
                borderRadius="10px"
                cursor="pointer"
                position="absolute"
                bottom="0"
                right="0"
              >
                <Center>
                  <Text
                    fontFamily="Roboto, sans-serif"
                    fontSize="12px"
                    fontWeight="900"
                    color="#FF2424"
                    userSelect="none"
                  >
                    {unread}
                  </Text>
                </Center>
              </Box>
            )}
          </Center>
        </Box>
      )}
      {expanded && (
        <Box height="100%" width="100%">
          <Box marginLeft="auto" width="40px" height="40px">
            <IconButton
              onClick={() => setExpanded(false)}
              aria-label="Change sprite"
              variant="ghost"
              icon={<FontAwesomeIcon icon={faTimes} />}
            />
          </Box>
          <Box height="100%" marginTop="-40px">
            <Chat />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ChatButton;
