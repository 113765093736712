import { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  VStack,
  Spinner,
  Center,
  Text,
  Button,
  Image,
  Box,
  HStack,
  Wrap,
  Skeleton,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { Trait } from '../components/Trait';
import { NavLink } from '../components/NavLink';

export const CreateFreeRoom = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [clipBoardSuccess, setClipBoardSuccess] =
    useState<boolean | undefined>(undefined);
  const [room, setRoomName] = useState<string>();
  const [expiration, setExpiration] = useState<number>();
  const { push } = useHistory();

  const goToLobby = () => {
    push(`/lobby/${room}`);
  };

  const goToOrder = () => {
    push(`/order`);
  };

  const goToHome = () => {
    push(`/`);
  };

  const copyToClipboard = () => {
    const url = `https://www.hurv.io/${room}`;
    navigator.clipboard.writeText(url).then(
      () => {
        setClipBoardSuccess(true);
        console.log('Async: Copying to clipboard was successful!');
      },
      (err) => {
        setClipBoardSuccess(false);
        console.error('Async: Could not copy text: ', err);
      }
    );
    setTimeout(() => {
      setClipBoardSuccess(undefined);
    }, 2000);
  };

  const createPublicRoom = () => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}api/create-public-room`, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(res.statusText);
      })
      .then((data) => {
        const { roomName, status, validUntil } = data;
        if (status === 'success') {
          setRoomName(roomName);
          setExpiration(Number(validUntil));
          sessionStorage.setItem('NEWLY_CREATED_ROOM_NAME', roomName);
          sessionStorage.setItem(
            'NEWLY_CREATED_ROOM_CREATED_TIME',
            `${Date.now()}`
          );
          sessionStorage.setItem(
            'NEWLY_CREATED_ROOM_EXPIRATION_TIME',
            validUntil
          );
        } else {
          console.error('Could not create room ID');
          setErrorOccurred(true);
        }
      })
      .catch((error) => {
        console.error(error);
        setErrorOccurred(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const checkAndCreatePublicRoom = () => {
    const sessionStoreRoomName = sessionStorage.getItem(
      'NEWLY_CREATED_ROOM_NAME'
    );
    const sessionStoreRoomCreatedTime = sessionStorage.getItem(
      'NEWLY_CREATED_ROOM_CREATED_TIME'
    );
    const sessionStoreRoomExpirationTime = sessionStorage.getItem(
      'NEWLY_CREATED_ROOM_EXPIRATION_TIME'
    );
    // Mock rooms in development
    if (process.env.NODE_ENV === 'development') {
      setRoomName('dev-room-id');
      setExpiration(1835462865831);
      setIsLoading(false);
    }
    // Use newly created room
    else if (
      sessionStoreRoomName &&
      sessionStoreRoomCreatedTime &&
      Number.isNaN(Number(sessionStoreRoomCreatedTime)) === false
    ) {
      const parsedStoredCreatedTime = Number(sessionStoreRoomCreatedTime);
      const parsedStoredExpirationTime = Number(sessionStoreRoomExpirationTime);
      const timeNow = Date.now();
      if (parsedStoredCreatedTime + 1000 * 60 * 10 > timeNow) {
        setRoomName(sessionStoreRoomName);
        setExpiration(parsedStoredExpirationTime);
        setIsLoading(false);
      } else {
        createPublicRoom();
      }
    } else {
      createPublicRoom();
    }
  };

  useEffect(() => {
    checkAndCreatePublicRoom();
  }, []);

  return (
    <Box
      cursor={isLoading ? 'progress' : 'default'}
      height="100%"
      backgroundColor="rgba(0,0,0,0.6)"
    >
      <Image
        src="/assets/office-small.png"
        boxSize="100%"
        objectFit="cover"
        style={{
          imageRendering: 'pixelated',
        }}
        filter="blur(1px)"
        position="fixed"
        zIndex="-1"
      />
      <Center height="100%">
        {true && (
          <HStack animation="rollUp 0.35s" spacing="0">
            <Box
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              backgroundColor="#FFF"
              borderRadius="12px 0 0 12px"
              pt="12px"
              pb="24px"
              px="24px"
              maxWidth="520px"
              height="504px"
            >
              <Text
                onClick={() => goToHome()}
                cursor="pointer"
                fontSize="22px"
                color="#1B3A57"
              >
                Hurv
              </Text>
              <Box
                justify="center"
                direction={{ base: 'column', md: 'row' }}
                spacing="24px"
                px="48px"
                pb="24px"
                pt="12px"
              >
                <Text
                  fontSize={{ base: '29px', md: '36px' }}
                  fontWeight="700"
                  textAlign="center"
                  px="18px"
                  mb="24px"
                  lineHeight="1.2"
                >
                  Welcome to your new space 🎉
                </Text>

                <Button
                  height="68px"
                  width="100%"
                  mb="12px"
                  size="lg"
                  border="2px solid #DFDFDF"
                  variant="ghost"
                  cursor={!isLoading && !errorOccurred ? 'copy' : 'wait'}
                  onClick={() => {
                    if (!isLoading && !errorOccurred) {
                      copyToClipboard();
                    }
                  }}
                  paddingLeft="0"
                >
                  {isLoading && <Spinner />}
                  {errorOccurred && <Text>An error occurred.</Text>}
                  {!isLoading && !errorOccurred && (
                    <VStack
                      width="100%"
                      paddingLeft="12px"
                      alignItems="flex-start"
                    >
                      <HStack fontSize="18px" spacing="0">
                        <Text fontWeight="700" color="#1B3A57">
                          hurv.io/
                        </Text>
                        <Text>{room}</Text>
                      </HStack>
                      {clipBoardSuccess === undefined && (
                        <HStack fontSize="16px">
                          <FontAwesomeIcon color="#1B3A57" icon={faLink} />
                          <Text color="#1B3A57">
                            Copy this shareable room link
                          </Text>
                        </HStack>
                      )}
                      {clipBoardSuccess === true && (
                        <Text fontSize="16px">Copied!</Text>
                      )}
                      {clipBoardSuccess === false && (
                        <Text color="#EA3535" fontSize="16px">
                          Could not copy to clipboard.
                        </Text>
                      )}
                    </VStack>
                  )}
                </Button>
                <VStack
                  mb="24px"
                  marginLeft="6px"
                  alignItems="flex-start"
                  spacing="1"
                >
                  <Skeleton isLoaded={!isLoading}>
                    <Trait trait="Free" />
                  </Skeleton>
                  {expiration && (
                    <Skeleton isLoaded={!isLoading}>
                      <Trait
                        trait={`Valid until ${new Date(
                          expiration
                        ).toLocaleString(undefined, {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                        })}`}
                      />
                    </Skeleton>
                  )}
                  <Skeleton isLoaded={!isLoading}>
                    <Trait trait="Up to 8 concurrent users" />
                  </Skeleton>
                </VStack>
                <Button
                  mb="12px"
                  textColor="#fff"
                  background="#1B3A57"
                  borderRadius="5px"
                  _hover={{ bg: '#77a6b5' }}
                  size="lg"
                  onClick={() => goToLobby()}
                  disabled={!room || isLoading || errorOccurred}
                >
                  <Text>Next</Text>
                </Button>
                <VStack spacing="0" alignItems="flex-start">
                  <Wrap fontSize="14px" spacing="0" whiteSpace="nowrap">
                    <Text paddingRight="4px">Want</Text>
                    <Text paddingRight="4px" fontWeight="700">
                      unlimited time,
                    </Text>
                    <Text paddingRight="4px">more</Text>
                    <Text paddingRight="4px" fontWeight="700">
                      slots,
                    </Text>
                    <Text paddingRight="4px">and a</Text>
                    <Text fontWeight="700">custom domain?</Text>
                  </Wrap>
                  <NavLink
                    label="Create a paid space instead."
                    onClick={() => goToOrder()}
                    goToRoute="order"
                    fontSize="16px"
                    fontWeight="700"
                    color="#1B3A57"
                    cursor="pointer"
                  />
                </VStack>
              </Box>
            </Box>
            <Box
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              width="280px"
              height="504px"
              overflow="hidden"
              borderRadius="0 12px 12px 0"
            >
              <Image
                src="/assets/user_in_empty_office.png"
                boxSize="522px"
                objectFit="cover"
                style={{
                  imageRendering: 'pixelated',
                }}
              />
            </Box>
          </HStack>
        )}
      </Center>
    </Box>
  );
};

export default CreateFreeRoom;
