// @ts-nocheck
import Peer from 'peerjs';
import { selectorFamily, selector, DefaultValue } from 'recoil';
import { hurvClient } from '../../api';
import { villagerFamilyState } from '../villagers';
import {
  callFamilyState,
  callListState,
  selfStreamState,
  selfIsCameraEnabledState,
  selfIsMicrophoneEnabledState,
  selfScreenStreamState,
  maximizedCallIdState,
} from './atoms';

interface OpenCall extends Call {
  peerCall?: Peer.MediaConnection;
}

export const callFamilySelector = selectorFamily({
  key: 'call-list-family',
  get:
    (id) =>
    ({ get }) =>
      get(callFamilyState(id)),
  set:
    (id) =>
    ({ set }, call) => {
      set(callFamilyState(id), call);
    },
});

export const callListSelector = selector<OpenCall['id'][]>({
  key: 'call-list',
  set: ({ set }, list) => {
    if (list instanceof DefaultValue) {
      set(callListState, list);
      return;
    }
    const callList: OpenCall[] = [];
    list.forEach((call) => {
      callList.push(call.id);
      set(callFamilyState(call.id), call);
    });

    set(callListState, callList);
  },
  get: ({ get }) => get(callListState),
});

export const addCallSelector = selector({
  key: 'add-call',
  set: ({ set, get }, call) => {
    console.log('Calling user with id: ', call.id);
    const list = get(callListState);
    const villager = get(villagerFamilyState(call.id));
    set(callListState, [
      ...list.filter((_callId) => _callId !== call.id),
      call.id,
    ]);
    set(callFamilyState(call.id), {
      ...call,
      name: villager?.name || call?.name,
    });
  },
});

export const removeCallSelector = selector({
  key: 'remove-call',
  set: ({ set, get }, callId) => {
    const list = get(callListState);
    set(callListState, [...list.filter((_callId) => _callId !== callId)]);
  },
});

export const selfStreamSelector = selector<MediaStream>({
  key: 'self-stream',
  set: ({ set }, selfStream) => set(selfStreamState, selfStream),
  get: ({ get }) => get(selfStreamState),
});

export const selfScreenStreamSelector = selector<MediaStream | null>({
  key: 'self-screen-stream',
  set: ({ set }, selfScreenStream) =>
    set(selfScreenStreamState, selfScreenStream),
  get: ({ get }) => get(selfScreenStreamState),
});

export const selfIsCameraEnabledSelector = selector<
  Villager['media']['isCameraEnabled']
>({
  key: 'self-is-camera-enabled',
  set: ({ set, get }, selfIsCameraEnabled) => {
    const streamState = get(selfStreamState);
    if (streamState) {
      streamState.getVideoTracks().forEach((track) => {
        // eslint-disable-next-line no-param-reassign
        track.enabled = selfIsCameraEnabled;
        set(selfIsCameraEnabledState, selfIsCameraEnabled);
        hurvClient.toggleCamera(selfIsCameraEnabled);
      });
    }
  },
  get: ({ get }) => get(selfIsCameraEnabledState),
});

export const selfIsMicrophoneEnabledSelector = selector<
  Villager['media']['isMicrophoneEnabled']
>({
  key: 'self-is-microphone-enabled',
  set: ({ set, get }, selfIsMicrophoneEnabled) => {
    const streamState = get(selfStreamState);
    if (streamState) {
      streamState.getAudioTracks().forEach((track) => {
        // eslint-disable-next-line no-param-reassign
        track.enabled = selfIsMicrophoneEnabled;
        set(selfIsMicrophoneEnabledState, selfIsMicrophoneEnabled);
        hurvClient.toggleMicrophone(selfIsMicrophoneEnabled);
      });
    }
  },
  get: ({ get }) => get(selfIsMicrophoneEnabledState),
});

export const maximizedCallIdSelector = selector<string | undefined>({
  key: 'maximized-media-key',
  set: ({ set }, maximizedCallId) => set(maximizedCallIdState, maximizedCallId),
  get: ({ get }) => get(maximizedCallIdState),
});
