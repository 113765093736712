import {
  Box,
  VStack,
  HStack,
  Text,
  Center,
  Spinner,
  IconButton,
  BoxProps,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExpandAlt,
  faCompressAlt,
  faBell,
  faMicrophoneSlash,
  faVideoSlash,
} from '@fortawesome/free-solid-svg-icons';
import Peer from 'peerjs';
import { villagerFamilySelector } from '../../state/villagers';
import { hurvClient } from '../../api';
import { maximizedCallIdSelector } from '../../state/calls/selectors';
import { maxizedSelector } from '../../state/room/selectors';

const UserVideo = ({ stream }: { stream: MediaStream | null }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (stream && videoRef.current && !videoRef.current.srcObject) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  function handleCanPlay() {
    videoRef.current?.play();
  }

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video ref={videoRef} onCanPlay={handleCanPlay} autoPlay playsInline />
  );
};

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

interface MediaFrameProps extends BoxProps {
  callId: string;
  peerCall: Peer.MediaConnection;
}

export const MediaFrame = ({ callId, peerCall, ...rest }: MediaFrameProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasNotified, setHasNotified] = useState<boolean>(false);
  const villager = useRecoilValue(villagerFamilySelector(callId));
  const [maximizedMediaCallId, setMaximizedMediaCallId] = useRecoilState(
    maximizedCallIdSelector
  );
  const isMaximizedMediaGrid = useRecoilValue(maxizedSelector);

  const handleLoadingState = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    peerCall.on('stream', handleLoadingState);

    return () => {
      peerCall.off('stream', handleLoadingState);
    };
  }, []);

  useEffect(() => {
    // @ts-ignore remoteStream should exist on MediaConnection if call has started
    if (peerCall.remoteStream) {
      handleLoadingState();
    }
  }, []);

  const handleNotify = () => {
    hurvClient.notifyVillager(villager.id);
    setHasNotified(true);
  };

  const toggleExpandMedia = () => {
    if (maximizedMediaCallId === undefined) {
      setMaximizedMediaCallId(callId);
    } else {
      setMaximizedMediaCallId(undefined);
    }
  };

  return (
    <Box
      pointerEvents="auto"
      key={callId}
      borderRadius="12px"
      backgroundColor="#3F3F3F"
      overflow="hidden"
      border="1px solid #202020"
      zIndex="2"
      position="relative"
      animation="rollUp 0.35s"
      {...rest}
    >
      <VStack
        zIndex="1"
        top="0px"
        borderRadius="12px"
        position="absolute"
        overflow="hidden"
        justifyContent="flex-end"
        alignItems="flex-start"
        {...rest}
      >
        <Box
          position="absolute"
          top="0px"
          left="0"
          right="0"
          padding="6px"
          zIndex="1"
        >
          <HStack justify="space-between">
            <Box>
              {villager?.data.status === 'blurred' &&
                !peerCall.metadata?.isScreenShare && (
                  <Box
                    height="24px"
                    py="1px"
                    px="6px"
                    marginBottom="auto"
                    borderRadius="4px"
                    backgroundColor="#202020"
                  >
                    <Text color="#FFA620" fontSize="14px" fontWeight="700">
                      Away from tab
                    </Text>
                  </Box>
                )}
            </Box>
            <HStack>
              {villager?.data.status === 'blurred' &&
                !peerCall.metadata?.isScreenShare && (
                  <Box>
                    {!hasNotified && (
                      <IconButton
                        onClick={() => handleNotify()}
                        height="24px"
                        width="24px"
                        aria-label="Notify User"
                        colorScheme="orange"
                        variant="solid"
                        icon={<FontAwesomeIcon color="#FFF" icon={faBell} />}
                      />
                    )}
                    {hasNotified && (
                      <Box
                        height="24px"
                        py="1px"
                        px="6px"
                        marginBottom="auto"
                        borderRadius="4px"
                        backgroundColor="#202020"
                      >
                        <Text color="#42E583" fontSize="14px">
                          Notified!
                        </Text>
                      </Box>
                    )}
                  </Box>
                )}
              {isMaximizedMediaGrid && (
                <IconButton
                  onClick={() => toggleExpandMedia()}
                  height="24px"
                  width="24px"
                  aria-label="Expand"
                  colorScheme="blue"
                  variant="solid"
                  icon={
                    <FontAwesomeIcon
                      color="#FFF"
                      icon={maximizedMediaCallId ? faCompressAlt : faExpandAlt}
                    />
                  }
                />
              )}
            </HStack>
          </HStack>
        </Box>

        <Center width="100%" height="100%" position="absolute">
          {isLoading && <Spinner />}
          {!isLoading && !villager?.media.isCameraEnabled && (
            <Box
              minWidth="50px"
              maxWidth="50px"
              height="50px"
              borderRadius="22px"
              marginRight="12px"
              style={{ imageRendering: 'pixelated' }}
              overflow="hidden"
            >
              <Box
                marginLeft="-807px"
                marginTop="-23px"
                width="1080px"
                height="80px"
                backgroundImage={`url(/assets/characters/${villager.data.sprite}_idle_anim_32x32.png)`}
                backgroundSize="100%"
              />
            </Box>
          )}
        </Center>
        <HStack spacing="6px" width="100%" padding="6px">
          <Box
            backgroundColor="#202020"
            py="1px"
            px="6px"
            borderRadius="4px"
            height="24px"
          >
            <Text fontSize="14px" color="white">
              {`${capitalizeFirstLetter(villager?.name)}${
                peerCall.metadata?.isScreenShare ? ' - Screen' : ''
              }` || 'Anonymous'}
            </Text>
          </Box>
          {!peerCall.metadata?.isScreenShare && (
            <HStack spacing="6px">
              {!villager?.media.isMicrophoneEnabled && (
                <Box
                  backgroundColor="#202020"
                  py="1px"
                  px="5px"
                  borderRadius="4px"
                  fontSize="13px"
                  width="26px"
                  height="24px"
                >
                  <FontAwesomeIcon color="#EA3535" icon={faMicrophoneSlash} />
                </Box>
              )}
              {!villager?.media.isCameraEnabled && (
                <Box
                  backgroundColor="#202020"
                  py="1px"
                  px="7px"
                  borderRadius="4px"
                  fontSize="11px"
                  width="26px"
                  height="24px"
                >
                  <Box marginTop="3px">
                    <FontAwesomeIcon color="#EA3535" icon={faVideoSlash} />
                  </Box>
                </Box>
              )}
            </HStack>
          )}
        </HStack>
      </VStack>
      <UserVideo
        // @ts-ignore
        stream={peerCall.remoteStream}
      />
    </Box>
  );
};

export default MediaFrame;
